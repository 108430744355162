import { Component, effect, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileService } from 'src/app/features/accounts/services/profile/profile-services.service';
import { EnhanceQuoteService } from 'src/app/features/enhance-quotes/services/enhance-quote.service';
import { CommanCardDetails } from 'src/app/models/comman-card-model';
import { DraftModel } from 'src/app/models/draft-model';
import { OrderModel } from 'src/app/models/order-model';
import { CardType } from 'src/assets/config/constants';
import { ReasonPopComponent } from '../dialogbox/reason-pop/reason-pop.component';
import { MatDialog } from '@angular/material/dialog';
import { QuoteCreationService } from 'src/app/features/quotes/services/quote-creation.service';
import { ToastrService } from 'ngx-toastr';
import { ProjectModel } from 'src/app/models/project-model';

@Component({
  selector: 'app-comman-list-card',
  templateUrl: './comman-list-card.component.html',
  styleUrls: ['./comman-list-card.component.scss']
})
export class CommanListCardComponent {
  @Input() typeOfCard!: CardType;
  CardType = CardType;
  enhanceQuoteUiFlag: boolean;
  @Input() cardListItem!: DraftModel[] | OrderModel[] | ProjectModel[] ;
  commanCardDetails:CommanCardDetails[]=[];
  isInactiveAccount: boolean = false;
  @Input() isArchivedQuotes: boolean =false;
  @Input() listOfCount: number =5;
  
 

  constructor(
    public enhanceQuotesService: EnhanceQuoteService,
    private profileService: ProfileService,
    private router: Router,
    private box: MatDialog,
    private quoteCreationService: QuoteCreationService,
    private toastr: ToastrService,
  ) {
    effect(() => {
      this.enhanceQuoteUiFlag = this.enhanceQuotesService.enhanceQuoteUiSignal();
    })
  }

  ngOnChanges() {
    if (this.typeOfCard === this.CardType.Quotes && this.cardListItem) {
      console.log("card list", this.cardListItem)
      this.commanCardDetails = (this.cardListItem as DraftModel[]).map((cardListItem: DraftModel) => ({
        cardHeaderTitle:cardListItem.quoteName ||'',
        cardHeaderNumber:cardListItem.name,
        cardId:cardListItem.id,
        projectName: cardListItem.projectDetails?.name || '', 
        projectId: cardListItem.projectDetails?.projectNumber|| '',
        projectStartDate: cardListItem.projectDetails?.startDate || '',
        projectEndDate: cardListItem.projectDetails?.endDate || '',
        cardStartDate: cardListItem.startDate ||"",
        cardEndDate: cardListItem.endDate ||'',
        quoteCurrentStep: cardListItem.currentStatus,
        cardStatusOrType: cardListItem.status,
        archievedConfirm:cardListItem.archievedConfirm,
      }));
    } else if (this.typeOfCard === this.CardType.Orders && this.cardListItem) {
      this.commanCardDetails = (this.cardListItem as OrderModel[]).map((cardListItem: OrderModel) => ({
        cardHeaderTitle:cardListItem.quoteName ||'',
        cardHeaderNumber:cardListItem.name,
        cardId:cardListItem.quoteId,
        projectName: cardListItem.projectDetails?.name || '', 
        projectId: cardListItem.projectDetails?.projectNumber || '',
        projectStartDate: cardListItem.projectDetails?.startDate || '',
        projectEndDate: cardListItem.projectDetails?.endDate || '',
        cardStartDate: cardListItem.startDate ||"",
        cardEndDate: cardListItem.endDate ||'',
        quoteCurrentStep: 0,
        cardStatusOrType: cardListItem.status,
        contractNumber:cardListItem.contractNumber ??"",
        contractId:cardListItem.contractId ??""
      }));
    }else if(this.typeOfCard === this.CardType.Projects && this.cardListItem){

      this.commanCardDetails = (this.cardListItem as ProjectModel[]).map((cardListItem: ProjectModel) => ({
        cardHeaderTitle: cardListItem.name||'',
        cardHeaderNumber:cardListItem.projectNumber ||'',
        cardId:cardListItem.id,
        projectName: cardListItem.projectNumber || '', 
        projectId: cardListItem.id || '',
        cardStartDate: cardListItem.startDate || '',
        cardEndDate: cardListItem.endDate || '',
        quoteCurrentStep: 0,
        cardStatusOrType: cardListItem.projectType ||'',
        noOfQuotes: cardListItem.noOfQuotes ||0,
        noOfOrders: cardListItem.noOfOrders ||0,
      }));
    }
  }

  ngOnInit(){
    this.isInactiveAccount =
    this.profileService?.selectedAccount().myussUserRole === "Read Only" ||
    this.profileService.isAccountInActive();
  }


  toCompleteQuote(quote: CommanCardDetails) {
    // if archived quotes navigate to archive quotes
    if (this.isArchivedQuotes) {
      this.router.navigate(["quotes/archivequotes", quote.cardId]);
      return;
    }
    if (this.isInactiveAccount) {
      this.router.navigate(["/quotes/quotesummary", quote.cardId]);
      return;
    } else {
      // this.router.navigate(["quotes/quotecreation", quote.id]);
      if (this.enhanceQuotesService.enhanceQuoteUiSignal()) {
        this.router.navigate(["quotes/v2/quote-creation", quote.cardId]);
      }else{
        this.router.navigate(["quotes/quotecreation", quote.cardId]);
      }
    }
  }

  /**
 * takes reject quote reason and cancels the quote
 * @param quote
 */
  deleteQuote(quote: CommanCardDetails) {
    const box = this.box.open(ReasonPopComponent, {
      minWidth: "40%",
      disableClose: true,
    });
    box.afterClosed().subscribe((reason) => {
      if (reason) {
        this.quoteCreationService
          .onAcceptAndReject({
            quoteId: quote.cardId??'',
            status: "Rejected",
            rejectReason: reason.reason,
            rejectReasonFeedback: reason.feedback,
          })
          .subscribe((res) => {
            if (res && res["status"] === 1000) {
              // Find the index of the item you want to remove
              const index = this.commanCardDetails.findIndex(card => card.cardId === quote.cardId);
              // If the item is found, remove it using splice
              if (index !== -1) {
                this.commanCardDetails.splice(index, 1);
              }
              this.toastr.success(
                `Quote ${quote.cardHeaderNumber} deleted successfully.`
              );
            } else {
              this.toastr.error(`Failed to delete Quote ${quote.cardHeaderNumber}.`);
            }
          });
      }
    });
  }


  /**
   * on click navigate to order summary of perticular order
   * @param order
   */
  viewOrder(order: CommanCardDetails) {
    if(order.contractNumber){
      this.router.navigate(["orders/ordersummary", order.contractId]);
    }
  }

  viewProject(project: CommanCardDetails) {
    this.router.navigateByUrl(`projects/project-details/${project.cardId}`);
  }

  trackByCardDetails(index:number,card:CommanCardDetails){
   return card.cardId;
  }

  // clone quote function 
  onClone(quoteId : string){
    this.enhanceQuotesService.onClone(quoteId,'quote')
  }


}
