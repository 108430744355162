<section fxLayout="row wrap" class="wrapper my-2" *ngIf="shouldShowComponent()">
  <div [formGroup]="quoteForm" fxFlex="69"  fxFlex.xs="100" [ngClass]="{'unit_and_services':screen==='quotes','edit_order_add_product_wrapper':screen==='editOrder'}" >
    <div *ngIf="currentState?.quoteName" class="px-2 white_bg border_radius_10 p-1 mb-2">
      <app-quote-name></app-quote-name>
    </div>
    <!-- quote title -->
    <div *ngIf="screen==='quotes'" class="quote_title white_bg border_radius_10 px-2 py-2 mb-2">
      <!-- quote Name  -->

      <!-- quote title -->
      <h2 class="headers px-2">{{ label ? label : "Custom Quote" }}</h2>
      <!-- service -->
      <p class="px-2"></p>
      <!-- radio btns -->
      <div class="pb-3 px-2" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div>
          <p>End Date Type:</p>
        </div>
        <!-- radio btns -->
        <mat-radio-group (change)="radioForDateAndDuration($event)" aria-label="Select an option"
          [formControl]="radioBtnForDateAndDuration">
          <mat-radio-button color="primary" checked [value]="true">Estimated</mat-radio-button>
          <mat-radio-button color="primary" [value]="false" selected>Fixed</mat-radio-button>
        </mat-radio-group>
        <mat-icon color="primary" class="error_icon mat_info_size"
        #toolTip="matTooltip"
          [matTooltip]="fixedDateMessage"
          aria-label="Button that displays a tooltip when focused or hovered over" (click)="toolTip.toggle()">
          info</mat-icon>

      </div>
      <!-- date selectors -->
      <div fxLayout="row wrap">
        <!-- start date -->
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="px-2" color="primary" appearance="outline">
          <mat-label>Start Date</mat-label>
          <input [min]="startDateMin" matInput formControlName="startDate" [matDatepicker]="picker1" (dateChange)="checkDayOfWeek($event.value)"/>

          <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1 color="primary"></mat-datepicker>
          <mat-error *ngIf="
              quoteForm.controls.startDate.errors?.required &&
              !quoteForm.controls.startDate.errors?.matDatepickerParse
            ">Required.</mat-error>
          <mat-error *ngIf="quoteForm.controls.startDate.errors?.matDatepickerMin">Start date is surpassed.</mat-error>
          <mat-error *ngIf="quoteForm.controls.startDate.errors?.matDatepickerParse">
            Invalid date.</mat-error>
        </mat-form-field>
        <!-- end date -->
        <mat-form-field fxFlex="50" fxFlex.xs="100" class="px-2" color="primary" appearance="outline">
          <mat-label>End Date</mat-label>
          <input [min]="endDateMin" matInput formControlName="endDate" [matDatepicker]="picker2" (dateChange)="checkDayOfWeek($event.value)" />
          <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-datepicker #picker2 color="primary"></mat-datepicker>
          <mat-error *ngIf="
              quoteForm.controls.endDate.errors?.required &&
              !quoteForm.controls.endDate.errors?.matDatepickerParse
            ">Required.</mat-error>
          <mat-error *ngIf="
              quoteForm.controls.endDate.errors?.matDatepickerParse ||
              quoteForm.controls.endDate.errors?.matDatepickerMin
            ">Invalid Date.</mat-error>
          <mat-error *ngIf="quoteForm.controls.endDate.errors?.invalidDate">End date must be greater than the start
            date.</mat-error>
            <mat-error *ngIf="quoteForm.controls.endDate.errors?.inValidEndDate">End date must be less than 10 years</mat-error>
        </mat-form-field>
      </div>
    </div>
    <!-- product section -->
    <div class="product_section white_bg border_radius_10  p-3" ngClass.xs="p-2">
      <div class="mb-2" *ngIf="screen==='editOrder'">
        <h2 class="headers px-2">Add New Unit Type</h2>
      </div>
      <div>
        <p class="px-2 pb-2" *ngIf="screen==='editOrder'">Note - Currently only sanitation products are available on the web-app. To avail other products please contact call center or  account rep.</p>
      </div>
      <div fxLayout="row wrap" fxLayoutAlign="start start" fxLayoutGap="10px">
        <mat-form-field appearance="outline" fxFlex="0 1 50" fxFlex.xs="1 0 50" class="add_product py-1">
          <mat-label>Add New Product</mat-label>
          <mat-select [formControl]="addProductFormControl" id="addProductId">
            <mat-option *ngFor="let bundle of bundles" [value]="bundle.bundleProductCode">
              {{ bundle.bundleName }}
            </mat-option>
            <mat-divider></mat-divider>
            <mat-option *ngFor="let list of standardQuotesList" [value]="list">
            <div class="standard_Quotes_List">
             <span>
               {{list.label2}}
            </span> 
            <span class="service_Name">

              {{list.service}}
            </span>

            </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
       
        <button class="add" mat-raised-button color="primary" (click)="addNewProduct()">
          Add
        </button>
      </div>
      <!-- Bundle -->

      <div [formGroup]="bundleFormGroup" class="bundle_Wrapper">
        <!-- bundle one -->
        <ng-container formArrayName="bundlesArray">
          <div [ngClass]="{'disabled-bundle':bundlegrp.disabled}" *ngFor="let bundlegrp of bundleRows.controls; let i = index" [formGroupName]="i"
            class="bundle w-100 mb-3" fxLayoutAlign="center center">
            <div  class="p-2 w-100 text-content" fxLayoutAlign="start center">
              <div class="img_wrapper">
                <img src="../../../../assets/img/restroom.png" alt="" />
              </div>
              <div class="text_wrapper" fxFlex="100">
                <div class="bundle_info">
                  <h4 class="px-2 pb-3">{{ bundlegrp.value.bundleName }}</h4>

                  <!-- Quantity -->
                  <mat-form-field  *ngIf="!isMobile" appearance="outline" class="px-2 py-1 sm_inputs qty web_view"
                    style="font-size: 0.8rem">
                    <mat-label>Qty</mat-label>
                    <mat-select formControlName="bundleQty">
                      <mat-option *ngFor="let unit of [].constructor(perQuoteUnitLimit),let i = index"
                        [value]="i +1">{{i +1}}</mat-option>

                    </mat-select>
                  </mat-form-field>
                  <!--asset_and_frequency  -->
                  <div *ngIf="!isMobile" class="asset_and_frequency web_view">
                    <!-- Assets -->
                    <mat-form-field appearance="outline" class="px-2 py-1 sm_inputs dropdown_width multi-select">
                      <mat-label>Select Asset</mat-label>
                      <mat-select formControlName="productIdAsset">
                        <mat-option *ngFor="let asset of selectedBundleArray[i].assetList" [value]="asset">
                          {{ asset.assetName }}
                        </mat-option>
                      </mat-select>
                      <mat-error>Required</mat-error>
                    </mat-form-field>

                    <!-- frequency -->
                    <mat-form-field appearance="outline" class="px-2 py-1 sm_inputs dropdown_width multi-select">
                      <mat-label>Select Frequency</mat-label>
                      <mat-select formControlName="productIdService">
                        <mat-option *ngFor="
                            let service of selectedBundleArray[i].serviceList
                          " [value]="service">
                          {{ service.serviceName }}
                        </mat-option>
                      </mat-select>
                      <mat-error>Required</mat-error>
                    </mat-form-field>

                    <!-- additional services -->
                    <mat-form-field appearance="outline" class="px-2 py-1 sm_inputs dropdown_width multi-select">
                      <mat-label> Additional Services</mat-label>
                      <mat-select formControlName="additionalProduct" multiple>
                        <mat-option *ngFor="
                            let ancillaryService of selectedBundleArray[i]
                              .ancillaryServiceList
                          " [disabled]="
                            disableAdditionalService(
                              selectedBundleArray[i],
                              ancillaryService
                            )
                          " [value]="ancillaryService">
                          {{ ancillaryService.ancillaryServiceName }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <!-- for assests and frequency mobile view -->
            <div *ngIf="isMobile" class="w-100 p-2 mobile_view">
              <!-- Assets -->
              <mat-form-field fxFlex="1 0 50" appearance="outline"
                class="px-1 py-2 sm_inputs error-display-none multi-select">
                <mat-label>Asset</mat-label>
                <mat-select formControlName="productIdAsset">
                  <mat-option *ngFor="let asset of selectedBundleArray[i].assetList" [value]="asset">
                    {{ asset.assetName }}
                  </mat-option>
                </mat-select>
                <mat-error>Required</mat-error>
              </mat-form-field>

              <!-- frequency -->
              <mat-form-field fxFlex="1 0 50" appearance="outline"
                class="px-1 py-2 sm_inputs error-display-none multi-select">
                <mat-label>Frequency</mat-label>
                <mat-select formControlName="productIdService">
                  <mat-option *ngFor="let service of selectedBundleArray[i].serviceList" [value]="service">
                    {{ service.serviceName }}
                  </mat-option>
                </mat-select>
                <mat-error>Required</mat-error>
              </mat-form-field>
              <!-- additional services -->
              <mat-form-field fxFlex="1 0 50" appearance="outline"
                class="px-1 py-2 sm_inputs error-display-none multi-select">
                <mat-label> Additional Services</mat-label>
                <mat-select formControlName="additionalProduct" multiple>
                  <mat-option *ngFor="
                      let ancillaryService of selectedBundleArray[i]
                        .ancillaryServiceList
                    " [disabled]="
                      disableAdditionalService(
                        selectedBundleArray[i],
                        ancillaryService
                      )
                    " [value]="ancillaryService">
                    {{ ancillaryService.ancillaryServiceName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <!-- Quantity -->
              <mat-form-field fxFlex="1 0 50" appearance="outline" class="px-1 py-2 sm_inputs error-display-none">
                <mat-label>Qty</mat-label>
                <mat-select formControlName="bundleQty">
                  <mat-option *ngFor="let unit of [].constructor(perQuoteUnitLimit),let i = index" [value]="i +1">{{i
                    +1}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <!-- delete section -->
            <div  class="delete_btn" fxLayoutAlign="center center">
              <button [disabled]="bundlegrp.disabled" mat-icon-button (click)="deleteBundle(i)" color="warn">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
        <!-- bundle two -->
      </div>


    </div>
  </div>

  <div fxFlex="29.5"  fxFlex.xs="100" class="quote-summary">
    <mat-card class="tile border_tile_radius_top p-2 pl-3 mb-1">
      <h2>Quote Summary</h2>
    </mat-card>

    <mat-card class="tile p-2 border_tile_radius_bottom summary_wrapper" [ngClass]="{'summary_wrapper':screen==='quotes','edit_order_summary_wrapper':screen==='editOrder'}" >
      <div fxLayoutAlign="center" class="p-2">
        <table>
          <tr>
            <td *ngIf="screen==='quotes'">Start Date</td>
            <td *ngIf="screen==='quotes'" >{{ (quoteSummary.startDate | date : "MM/dd/yyyy") || "-" }}</td>
          </tr>
        </table>
        <table>
          <tr *ngIf="screen==='quotes'">
            <td>End Date</td>
            <td >{{ (quoteSummary.endDate | date : "MM/dd/yyyy") || "-" }}</td>
          </tr>
        </table>
      </div>
      <mat-divider></mat-divider>

      <div *ngFor="let quote of quoteSummary.bundles">
        <table class="p-2">
          <tr>
            <td>Product Name</td>
            <td>{{ quote.bundleName || "-" }}</td>
          </tr>
          <tr>
            <td>Asset</td>
            <td>{{ quote.productIdAsset?.assetName || "-" }}</td>
          </tr>
          <tr>
            <td>Quantity</td>
            <td>{{ quote.bundleQty || "-" }}</td>
          </tr>
          <tr>
            <td>Frequency</td>
            <td>{{ quote.productIdService?.serviceName || "-" }}</td>
          </tr>
          <tr>
            <td class="vertical-align-top">Additional Services</td>
            <td>
              <p *ngIf="quote.additionalProduct?.length === 0">-</p>
              <p *ngFor="let ancillaryService of quote.additionalProduct">
                {{ ancillaryService.ancillaryServiceName || "-" }}
              </p>
            </td>
          </tr>
        </table>
        <mat-divider></mat-divider>
      </div>
    </mat-card>

    <div *ngIf="screen==='quotes' && !isMobile" class="w-100" fxLayoutAlign="start center" fxLayout="column" fxLayout.xs="row" ngClass.xs="gap">
      <button mat-raised-button class="mt-2 w-100" (click)="showQuoteSummary()" [disabled]="isLoading">
        <!-- <mat-spinner
            *ngIf="isLoading"
            diameter="20"
            color="primary"
          ></mat-spinner> -->
        <span *ngIf="!isLoading">Save Draft</span>
        <div class="loading" *ngIf="isLoading">
          <span>Gathering Info</span>
          <div class="loading ml-1 mt-1">
            <span class="loading__dot"></span>
            <span class="loading__dot"></span>
            <span class="loading__dot"></span>
          </div>
        </div>
      </button>
      <button class="mt-2 w-100" mat-raised-button color="primary" (click)="requestQuote()" id="requestQuoteBtnId"
        [disabled]="isLoading">
        <span>Get Quote</span>

      </button>

    </div>
    <div *ngIf="screen==='editOrder'" class="w-100" fxLayoutAlign="start center" fxLayout="column">
      <button class="mt-2 w-100" mat-raised-button color="primary" (click)="addNewUnitType()">
        Next

      </button>

    </div>
  </div>





</section>



<div >

  <app-select-location-to-move     
  class="drawer_component" *ngIf="showSideDrawer" [caseData]="caseData" [orderData]="orderData" (addDetailsDrawer)="closeDrawer($event)"></app-select-location-to-move>

</div>