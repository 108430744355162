import { AuthConnect } from '../index';
export class AuthProvider {
  /** @hidden */
  async checkOrGenerateKeys(config) {
    if (!this.key || !this.nonce) {
      this.key = await AuthConnect.getPKCEKey();
      this.nonce = await AuthConnect.getRandomString(20);
    }
    if (!this.key || !this.nonce) {
      throw Error('Key or nonce could not be generated');
    }
    if ((config === null || config === void 0 ? void 0 : config.logLevel) === 'DEBUG') {
      console.debug('nonce', this.nonce);
      console.debug('key', this.key);
    }
  }
  /** @hidden */
  usePKCE(config) {
    var _a;
    return config.platform !== 'web' || ((_a = config.web) === null || _a === void 0 ? void 0 : _a.authFlow) === 'PKCE';
  }
}
