import { NavigationStart , Router } from '@angular/router';
import { Injectable, effect, signal } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import {
  BehaviorSubject,
  Observable,
  catchError,
  firstValueFrom,
  map,
  of,
} from "rxjs";
import {
  AssetLocation,
  OrderModel,
  EditOrderState,
  EditOrderModel,
  Contracts,
} from "src/app/models/order-model";
import { LoadingService } from "../../../shared/services/loading/loading.service";
import { UssApiService } from "../../myuss/services/uss-api/uss-api.service";
import { DraftModel } from "src/app/models/draft-model";
import { HttpParams } from "@angular/common/http";
import { ProfileService } from "../../accounts/services/profile/profile-services.service";
import { UserProfile } from "src/app/models/profile-model";


@Injectable({
  providedIn: "root",
})
export class OrderService {
  public currentEditOrder$ = new BehaviorSubject<EditOrderState>(
    new EditOrderState()
  );
  // behaviorsubject for products
  public orderSummaryBehaviorSubject = new BehaviorSubject<OrderModel | null>(
    null
  );
  // allproducts as observable
  orderSummary: Observable<OrderModel | null> =
    this.orderSummaryBehaviorSubject.asObservable();
  private contractDataBehaviorSubject = new BehaviorSubject<Contracts | null>(
    null
  );

  contractDataSummary: Observable<Contracts | null> =
    this.contractDataBehaviorSubject.asObservable();

  private deactivatedOrderBehaviorSubject = new BehaviorSubject<
    DraftModel[] | null
  >(null);
  deactivatedOrder: Observable<DraftModel[] | null> =
    this.deactivatedOrderBehaviorSubject.asObservable();

  account: Partial<UserProfile>;
  drawerState = signal<boolean>(false);
  isReduceQuantityState = signal<boolean>(false);
  isQuantitySingle = signal<boolean>(false);
  isCancelOrderFlow = signal<boolean>(false);

  
  constructor(
    private api: UssApiService,
    private profileService: ProfileService,
  ) {
    // effect(() => {
    //    this.account = this.profileService.selectedAccount();
    // });
  }

  getOrderById(id: String) {
    firstValueFrom(
      this.api.get(`accounts/${this.profileService.selectedAccount().accountId}/contracts/${id}`).pipe(
        map((res) => {
          if (res) {
            this.orderSummaryBehaviorSubject.next(res["data"]);
          }
        }),
        catchError((err) => {
          console.error(
            `error getting get order by id: ${JSON.stringify(err)}`
          );
          return of(null);
        })
      )
    );
  }

  getAllContract(filter: string,projectId?: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("status", filter);
    if(projectId){
      queryParams=queryParams.append("projectId", projectId)
    }
    return this.api
      .get(`accounts/${this.profileService.selectedAccount().accountId}/contracts`, {
        params: queryParams,
      })
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            this.contractDataBehaviorSubject.next(res["data"].contracts);
            return res["data"].contracts;
          }
        }),
        catchError((err) => {
          console.error(`error getting contracts: ${JSON.stringify(err)}`);
          throw err;
        })
      );
  }

  //group by function for asset location
  groupBy(
    assetLocation: AssetLocation[],
    objectKey1: string,
    objectKey2: string
  ) {
    const groupedData = assetLocation.reduce((acc, assetLocation) => {
      const key1 = assetLocation[objectKey1] || "-";
      const key2 = assetLocation[objectKey2] || "-";

      // Group by key1
      acc[key1] = acc[key1] || {};

      // Group by key2 within each key2 group
      acc[key1][key2] = acc[key1][key2] || [];
      acc[key1][key2].push(assetLocation);

      return acc;
    }, {});

    console.log("grouped Data", groupedData);
    return groupedData;
  }
  // to return array of object keys
  objectKeys(object: any): string[] {
    return Object.keys(object);
  }
  cancelOrder(orderData: {}) {
    return this.api.post(`contracts/cancel`, orderData).pipe(
      map((res: {}) => {
        if (res["status"] === 1000) {
          return res;
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(
          `error getting while cancelling order: ${JSON.stringify(err)}`
        );
        throw err;
      })
    );
  }

  mergeSameAsset(assetLocation: AssetLocation[]) {
    const mergedData = assetLocation?.reduce(
      (result: AssetLocation[], currentObj: AssetLocation) => {
        const existingObj: AssetLocation | null =
          result?.find(
            (item: AssetLocation) =>
              item.assetName === currentObj.assetName &&
              item.serviceName === currentObj.serviceName &&
              item.endDate === currentObj.endDate
          ) || null;

        if (existingObj) {
          existingObj.quantity += currentObj.quantity;
        } else {
          result.push({ ...currentObj });
        }

        return result;
      },
      []
    );
    return mergedData;
  }
  updateEditOrderValues(data: Partial<EditOrderState>) {
    const editOrdercurrentState = this.currentEditOrder$.value;
    this.currentEditOrder$.next({
      ...editOrdercurrentState,
      ...data,
    });
  }

  getCurrentEditOrderState() {
    return this.currentEditOrder$.value;
  }
  editOrderQuantity(
    editOrderData: EditOrderModel
  ): Observable<EditOrderModel | null> {
    const quantityChange = {
      quantityChange: [editOrderData],
    };
    console.log(`request`, quantityChange);

    return this.api.post(`contracts/edit-quantity`, quantityChange).pipe(
      map((res) => {
        console.log(`res sucess`, res);
        if (res["status"] === 1000) {
          return res;
        }
      }),
      catchError((err) => {
        console.log(`error updating order quantity: ${JSON.stringify(err)}`);

        throw err;
      })
    );
  }

  setDrawerState(state: boolean) {
    console.log("setDrawerState", state);
    this.drawerState.set(state);
  }

  setReduceQuantityState(state: boolean) {
    console.log("setReduceQuantityState", state);
    this.isReduceQuantityState.set(state);
  }
  setCancelOrderFlowState(state:boolean){
    console.log("SetCanceOrderFlowState", state);
    this.isCancelOrderFlow.set(state);
  }
  }