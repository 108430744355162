import { FormControl, Validators } from "@angular/forms";

export class ContactFormModel {
    firstName: FormControl<string>;
    lastName: FormControl<string>;
    email: FormControl<string>;
    phone: FormControl<string>;
    contactId: FormControl<string>;
    accountId?: FormControl<string>;
    fullName?: FormControl<string>;
    title?: FormControl<string>;
    
    constructor() {
      this.firstName = new FormControl<string>("", {
        validators: [Validators.required],
        nonNullable: true,
      });
      this.lastName = new FormControl<string>("", {
        validators: [Validators.required],
        nonNullable: true,
      });
      this.email = new FormControl<string>("", {
        validators: [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"),
        ],
        nonNullable: true,
      });
      this.phone = new FormControl<string>("", {
        validators: [Validators.required, Validators.minLength(10)],
        nonNullable: true,
      });
      this.contactId = new FormControl<string>("", { nonNullable: true });
      this.accountId = new FormControl<string>("", { nonNullable: true });
      this.fullName = new FormControl<string>("", { nonNullable: true });
      this.title = new FormControl<string>("", { nonNullable: true });
    }
  }