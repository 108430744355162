import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { PlytixProduct, ProdcutOption } from "./product-model";
import { QuoteModel } from "./quote-model";
import { Contact } from "./quoteState-model";
import { PurchaseOrder } from "./purchase-order.model";
import { ContactFormModel } from "./contact-model";

export class QuoteDetailsFormGroupModel {
  quoteName: FormControl<string>;
  projectId: FormControl<string>;
  quoteType: FormControl<string>;
  startDate: FormControl<string>;
  endDate: FormControl<string>;
  zipcode: FormControl<string>;
  contactExist: FormControl<boolean>;
  contactId: FormControl<string>;
  addressExist: FormControl<boolean>;
  addressId: FormControl<string>;
  accountId: FormControl<string>;
  customerType: FormControl<string>;
  businessType: FormControl<string>;
  orderType: FormControl<string>;
  duration: FormControl<string>;
  address: FormGroup<AddressFormModel>;
  contact: FormGroup<ContactFormModel>;
  estimatedEndDate: FormControl<string>;
  preVailingWagesRequired:FormControl<string>;

  constructor() {
    this.quoteName = new FormControl<string>("", {
      validators: [Validators.required, Validators.maxLength(40)],
      nonNullable: true,
    });
    this.projectId = new FormControl<string>("", { nonNullable: true });
    this.quoteType = new FormControl<string>("", { nonNullable: true });
    this.startDate = new FormControl<string>("", {
      validators: [Validators.required],
      nonNullable: true,
    });
    this.endDate = new FormControl<string>("", {
      validators: [Validators.required],
      nonNullable: true,
    });
    this.zipcode = new FormControl<string>("", { nonNullable: true });
    this.contactExist = new FormControl<boolean>(false, { nonNullable: true });
    this.contactId = new FormControl<string>("", { nonNullable: true });
    this.addressExist = new FormControl<boolean>(false, { nonNullable: true });
    this.addressId = new FormControl<string>("", { nonNullable: true });
    this.accountId = new FormControl<string>("", { nonNullable: true });
    this.customerType = new FormControl<string>("", { nonNullable: true });
    this.businessType = new FormControl<string>("", { nonNullable: true });
    this.orderType = new FormControl<string>("", { nonNullable: true });
    this.duration = new FormControl<string>("", { nonNullable: true });
    this.estimatedEndDate = new FormControl<string>("", { nonNullable: true });
    this.address = new FormGroup<AddressFormModel>(new AddressFormModel());
    this.contact = new FormGroup<ContactFormModel>(new ContactFormModel());
    this.preVailingWagesRequired= new FormControl<string>('no',{nonNullable:true})
  }
}

export class AddressFormModel {
  street: FormControl<string>;
  city: FormControl<string>;
  state: FormControl<string>;
  zipcode: FormControl<string>;
  country: FormControl<string>;
  latitude: FormControl<number>;
  longitude: FormControl<number>;
  shipToAddress: FormControl<boolean>;
  siteName?: FormControl<string>;
  addressExist?: FormControl<boolean>;
  addressRefId?: FormControl<string>;
  constructor() {
    this.addressRefId =new FormControl<string>("", { nonNullable: true });
    this.street = new FormControl<string>("", { nonNullable: true });
    this.city = new FormControl<string>("", { nonNullable: true });
    this.state = new FormControl<string>("", { nonNullable: true });
    this.zipcode = new FormControl<string>("", { nonNullable: true });
    this.country = new FormControl<string>("", { nonNullable: true });
    this.latitude = new FormControl<number>(0, { nonNullable: true });
    this.longitude = new FormControl<number>(0, { nonNullable: true });
    this.shipToAddress = new FormControl<boolean>(false, { nonNullable: true });
    this.siteName = new FormControl<string>("", { nonNullable: true });
    this.addressExist = new FormControl<boolean>(false, { nonNullable: true });
  }
}

export class SiteInformationFormModel {
  startTime: FormControl<string>;
  endTime: FormControl<string>;
  placementNotes: FormControl<string>;
  information: FormControl<string>;
  gateCode: FormControl<string>;
  idRequired: FormControl<boolean>;
  clearanceRequired: FormControl<boolean>;
  selectLocationType: FormControl<string>;
  constructor() {
    this.startTime = new FormControl<string>("", { nonNullable: true });
    this.endTime = new FormControl<string>("", { nonNullable: true });
    this.placementNotes = new FormControl<string>("", {
      validators: [Validators.required],
      nonNullable: true,
    });
    this.information = new FormControl<string>("", { nonNullable: true });
    this.gateCode = new FormControl<string>("", { nonNullable: true });
    this.idRequired = new FormControl<boolean>(false, { nonNullable: true });
    this.clearanceRequired = new FormControl<boolean>(false, {
      nonNullable: true,
    });
    this.selectLocationType = new FormControl<string>("Single Location", { nonNullable: true });
  }
}

export class productForm {
  bundle: FormControl<PlytixProductFormModel | null>;
  asset: FormControl<PlytixProductFormModel | null>;
  service: FormControl<PlytixProductFormModel | null>;
  quantity: FormControl<number>;
  additonalServices: FormArray<FormControl<PlytixProductFormModel>>;

  constructor() {
    this.bundle = new FormControl<PlytixProductFormModel | null>(null, [
      Validators.required,
    ]);
    this.asset = new FormControl<PlytixProductFormModel | null>(null, [
      Validators.required,
    ]);
    this.service = new FormControl<PlytixProductFormModel | null>(null, [
      Validators.required,
    ]);
    this.additonalServices = new FormArray<FormControl<PlytixProductFormModel>>(
      []
    );
    this.quantity = new FormControl<number>(1, { nonNullable: true });
    // this.feedback = new FormControl<string>("");
  }
}

export class PlytixProductFormModel {
  id: string;
  productCode: string;
  name: string;
  productType: string;
  requiredParentAsset: boolean;
  avaSfcpqTaxcode: string;
  productCategory: string;
  productChargeType: string;
  productSubscriptionType: string;
  assetSummary: string;
  description: string;
  priceBookId: string;
  gtin: string;
  thumbnail: string;
  created: string;
  plytixDescription: string;
  label: string;
  deliveryPickupDesc: string;
  cleanSanitaryDesc: string;
  features: string;
  assets: string[];
  imageGallery: string[];
  categories: string;
  family: string;
  sku: string;
  excerpt: string;
  status: string;
  productOptions?: ProdcutOption[];
  ancillaryServiceList: PlytixProductFormModel[];
  serviceList: PlytixProductFormModel[];
  assetList: PlytixProductFormModel[];
  checked?: boolean;
  disabled?: boolean;

  constructor() {
    this.id = "";
    this.productCode = "";
    this.name = "";
    this.productType = "";
    this.requiredParentAsset = false;
    this.avaSfcpqTaxcode = "";
    this.productCategory = "";
    this.productChargeType = "";
    this.productSubscriptionType = "";
    this.assetSummary = "";
    this.description = "";
    this.priceBookId = "";
    this.gtin = "";
    this.thumbnail = "";
    this.created = "";
    this.plytixDescription = "";
    this.label = "";
    this.deliveryPickupDesc = "";
    this.cleanSanitaryDesc = "";
    this.features = "";
    this.assets = [];
    this.categories = "";
    this.family = "";
    this.sku = "";
    this.excerpt = "";
    this.status = "";
    this.productOptions = [];
    this.ancillaryServiceList = [];
    this.serviceList = [];
    this.assetList = [];
    this.checked = false;
  }
}

export class QuoteModelDetailsV2 extends QuoteModel {
  selectedproductsFamily: string[];
  selectedProducts: SelectedProduct[];
  editIndex: number;
  isEdit: boolean = false;
  estimatedEndDate: string;
  currentStatus: number = 0;
  projectId: string;
  isAutoPay: boolean;
  paymentMethodId: string;
  containmentTrayRequired?: boolean;
  secondaryContactData: Contact;
  purchaseOrder:PurchaseOrder;
  preVailingWagesRequired:string;
  isCloneFlag: boolean = false;
}

export class SelectedProduct {
  bundle: PlytixProductFormModel | null;
  asset: PlytixProductFormModel | null;
  service: PlytixProductFormModel | null;
  quantity: number;
  additonalServices: PlytixProductFormModel[] | [];
}

export class BillingDetailsFormGroup{
  poNumber: FormControl<string>;  
  address: FormGroup<AddressFormModel>;
  contact: FormGroup<ContactFormModel>;
  secondaryContact: FormGroup<ContactFormModel>;

  constructor(){
    this.poNumber = new FormControl<string>("", {
      validators: [Validators.required],
      nonNullable: true,
    });
    this.address = new FormGroup<AddressFormModel>(new AddressFormModel());
    this.contact = new FormGroup<ContactFormModel>(new ContactFormModel());
    this.secondaryContact = new FormGroup<ContactFormModel>(new ContactFormModel());
  }

}
