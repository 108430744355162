export class User {
  data: UserProfile;
  message: string;
  status: number;
}

export class UserProfile {
  firstName: string;
  lastName: string;
  phone: string;
  accountName: string;
  businessType: string;
  customerType: string;
  accountId: string;
  email: string | undefined;
  emailVerified: boolean;
  accountNumber: string;
  autoPayRequirement: string;
  companyName?: string;
  id?: string;
  contactId: string;
  emailForCC: string[];
  accounts:Partial<UserProfile>[];
  poRequired: boolean;
  accountPaymentStatus: string;
  ussPortalUserId?: string;
  myussUserRole:string;
  myussModules: Record<string, boolean>;
  enforceRBAC:boolean;
  myussBypassTermsAndConditions:boolean;
  isPushNotificationActive:boolean;
}


export class UserProfileUpdate {
  firstName: string;
  lastName: string;
  accountId: string;
  contactId: string;
  emailIds: string[];
}

export class UpdateProfileResponse {
  message: string;
  status: number;
}


