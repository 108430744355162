<div class="p-3">
    <div class="cancel-div">
        <img src="../../../../../assets/img/icons/disabled_close_icon.svg" mat-dialog-close alt="Cancel icon">
    </div>
    <div class="text-div"> 
        <img src="../../../../../assets/img/icons/clone.svg" alt="Icon for clone confirmation message">
        <p class="mx-3">Would you like to clone your {{data}}?</p>
    </div>
    <div class="button-div">
        <button mat-flat-button (click)="onYes()" class="continue-btn btn">
         Yes
        </button>
        <button mat-flat-button mat-dialog-close class="continue-btn btn mx-2">
            No 
           </button>
    </div>
</div>
