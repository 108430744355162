<div class="fixed" ngClass.md="ml-2" ngClass.xs="ml-2" >
  <ng-container *ngIf="!loader">
    <div class="notificationsCard" id="dataTrueTag">
      <div fxLayoutAlign="start center" class="label">
        <p class="heading py-2 pl-2">
          Scheduled Service Activities
        </p>
      </div>
      <form [formGroup]="notifictionForm" id="notificationForm">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field appearance="outline" class="p-2 error-display-none gray_bg dropDown" fxFlex="50">
            <mat-select formControlName="duration" (selectionChange)="getNotification()">
              <mat-option *ngFor="let item of duration" [value]="item.days">{{item.show}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="p-2 error-display-none gray_bg dropDown" fxFlex="50">
            <mat-select formControlName="serviceType" (selectionChange)="filterNotificationByServiceType()">
              <mat-option *ngFor="let type of serviceType" [value]="type">{{type}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" class="p-2 error-display-none gray_bg" fxFlex="100">
            <input type="text" matInput class="gray_b" #input formControlName="orderId" placeholder="Order ID" (input)="filterBySearch(input.value)">
            <img class="searchSvg" src="../../../../assets/img/icons/search.svg" alt="search" matSuffix>
          </mat-form-field>
        </div>
      </form>

      <div class="p-2" id="service_acitivity_panel">
        <div *ngIf="hide" id="dataTrueTag" class="p-3" fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayoutAlign="start center">
            <div class="title">
              {{ alternateMessage }}
            </div>
          </div>
        </div>

        <mat-accordion *ngIf="!hide">
          <mat-expansion-panel *ngFor="let item of data;let i= index" [expanded]="i==0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{item.day}}, {{item.date | date:'MM/dd/yyyy'}} </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="service_details" *ngFor="let event of item.events" (click)="openServiceDetails(event.contractId)">
              <div fxLayoutAlign="space-between center">
                <h3>{{event.quoteNo}}</h3>
                <div class="_badge px-2" fxLayout="row" fxLayoutAlign="center center" [ngClass]="{'delivery_badge':event.status=='Delivery','pickup_badge':event.status=='Pickup','service_badge':event.status=='Service','install_badge':event.status=='Install','stakeDown_badge':event.status=='StakeDown'}">
                  <div class="colored-dot mr-1"></div>
                  <span>{{event.status}}</span>
                </div>
              </div>
              <p class="pt-2"> {{(event.siteAddress?.siteName+','+event.siteAddress?.street+','+ event.siteAddress?.city+","+ event.siteAddress?.state)}}</p>
            </div>
            <p *ngIf="!item.events.length">No data matching the filter </p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </ng-container>
  <div class="loader-wrapper" *ngIf="loader">
    <mat-spinner color="primary" [diameter]="50"></mat-spinner>
  </div>
</div>