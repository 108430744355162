import { NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { te } from "date-fns/locale";

@Component({
  selector: "app-tool-tip-template",
  standalone:true,
  templateUrl: "./tool-tip-template.component.html",
  styleUrls: ["./tool-tip-template.component.scss"],
  imports:[NgIf]
})
export class ToolTipTemplateComponent {
  @Input() background: string = "#334155";
  @Input() color: string = "#FFFFFF";
  @Input() text: string = "";
  @Input() imageUrl: string = "";
  @Input() fontSize: string = "";
  constructor() {
  }
}
