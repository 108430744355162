import { Injectable, NgZone } from "@angular/core";
import { Platform } from "@ionic/angular";
import {
  Auth0Provider,
  AuthConnect,
  AuthResult,
  ProviderOptions,
  TokenType,
} from "@ionic-enterprise/auth";

import { VaultService } from "../vault/vault.service";
import { BehaviorSubject, Observable } from "rxjs";
import { Router } from "@angular/router";
import { ConfigService } from "../config/config.service";
import {
  Auth0ClientFactory,
  AuthClientConfig,
  AuthConfig,
  AuthorizationParams,
  IdToken,
} from "@auth0/auth0-angular";
import { UserService } from "src/app/features/users/services/user.service";
import { DeviceDetectorService } from "ngx-device-detector";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private initializing: Promise<void> | undefined;
  private isNative: boolean;
  private provider = new Auth0Provider();
  private authOptions: ProviderOptions;
  public authResult: AuthResult | null = null;
  token: string = "";
  deviceInfo: any;
  screenWidth: number;

  private authenticationChange: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  public authenticationChange$: Observable<boolean>;

  constructor(
    private platform: Platform,
    private ngZone: NgZone,
    private vault: VaultService,
    private router: Router,
    private userService: UserService,
    private configService: ConfigService,
    private deviceService: DeviceDetectorService
  ) {
    this.isNative = platform.is("hybrid");

    this.loadProviderOptions();

    this.initialize();
    this.authenticationChange$ = this.authenticationChange.asObservable();
    this.isAuthenticated().then((authenticated) =>
      this.onAuthChange(authenticated)
    );
  }

  async loadProviderOptions() {
    this.authOptions = await this.configService.getProviderOptions();
  }

  private setup(): Promise<void> {
    return AuthConnect.setup({
      platform: this.isNative ? "capacitor" : "web",
      ios: {
        webView: "private",
      },
      android: {
        isAnimated: false,
        showDefaultShareMenuItem: false,
      },
      web: {
        uiMode: "current",
        authFlow: "PKCE",
      },
    });
  }

  private initialize(): Promise<void> {
    return (this.initializing = new Promise((resolve) => {
      this.setup().then(() => resolve());
    }));
  }

  public async login(): Promise<void> {
    await this.initialize();
    this.authResult = await AuthConnect.login(this.provider, this.authOptions);
    await this.saveAuthResult(this.authResult);
    this.lastLoginInfoUpdate();
  }

  public async logout(): Promise<void> {
    await this.initialize();
    this.authResult = await this.getAuthResult();
    try {
      await this.saveAuthResult(null);
      await AuthConnect.logout(new Auth0Provider(), this.authResult!);

      if (this.isNative) {
        this.router.navigate(["/landing-screen"]);
        location.reload();
      }
    } catch (error) {
      console.error("AuthConnect.logout", error);
    }
  }

  public async handleLogin() {
    const urlParams = new URLSearchParams(window.location.search);
    const queryEntries: Record<string, string> = {};
    urlParams.forEach((value, key) => {
      queryEntries[key] = value;
    });
    // current work around for loginWithRedirect using authClient because callback state not match with auth connect callback state
    if (!queryEntries["state"]?.startsWith("eyJ1")) {
      this.router.navigate(["/home"]);
    }

    this.authResult = await AuthConnect.handleLoginCallback(
      queryEntries,
      this.authOptions
    );
    await this.saveAuthResult(this.authResult);
    this.lastLoginInfoUpdate();
  }

  private async onAuthChange(isAuthenticated: boolean): Promise<void> {
    this.ngZone.run(() => {
      this.authenticationChange.next(isAuthenticated);
    });
  }

  public async getAuthResult(): Promise<AuthResult | null> {
    let authResult = await this.vault.getSession();
    if (authResult && (await AuthConnect.isAccessTokenExpired(authResult))) {
      await this.initialize();
      await this.saveAuthResult(null);
      await AuthConnect.logout(new Auth0Provider(), this.authResult!);
      return null;
    }

    return authResult;
  }

  public async isAuthenticated(): Promise<boolean> {
    await this.initialize();
    try {
      this.authResult = await this.vault.getSession();
      if (!this.authResult) {
        return false;
      }
      const { idToken } = this.authResult;
      if (!idToken) {
        throw new Error("No ID Token");
      }
      this.token = idToken;
      const expired = await AuthConnect.isAccessTokenExpired(this.authResult);
      if (expired) {
        return false;
      }
      return true;
    } catch (e) {
      console.error(e);
      await this.vault.clear();
      return false;
    }
  }

  public async getAccessToken(): Promise<string | undefined> {
    await this.initialize();
    const res = await this.getAuthResult();
    return res?.accessToken;
  }

  public async idTokenClaims(): Promise<IdToken | undefined> {
    await this.initialize();
    const res = await this.getAuthResult();
    if (res) {
      const data = (await AuthConnect.decodeToken(
        TokenType.id,
        res,
        false
      )) as IdToken;
      return data;
    }
    return undefined;
  }

  public async refreshAuth(authResult: AuthResult): Promise<AuthResult | null> {
    let newAuthResult: AuthResult | null = null;
    if (await AuthConnect.isRefreshTokenAvailable(authResult)) {
      try {
        newAuthResult = await AuthConnect.refreshSession(
          this.provider,
          authResult
        );
      } catch (err) {
        null;
      }
      this.saveAuthResult(newAuthResult);
    }

    return newAuthResult;
  }

  private async saveAuthResult(authResult: AuthResult | null): Promise<void> {
    if (authResult) {
      await this.vault.setSession(authResult);
    } else {
      await this.vault.clear();
    }
    this.onAuthChange(!!authResult);
  }

  loginWithRedirect(authorizationParams: AuthorizationParams) {
    const authClient = Auth0ClientFactory.createClient(
      this.getAuthClientConfig()
    );
    return authClient.loginWithRedirect({
      authorizationParams,
    });
  }

  private getAuthClientConfig(): AuthClientConfig {
    const auth0Config: AuthConfig = {
      domain: this.configService.config.auth0.domain, //this.configService.config.auth0.domain,
      clientId: this.configService.config.auth0.clientId, // this.configService.config.auth0.clientId,
      issuer: this.configService.config.auth0.domain,

      authorizationParams: {
        redirect_uri: this.authOptions.redirectUri,
        scope: this.authOptions.scope,
      },
    };
    const authClientConfig: AuthClientConfig = new AuthClientConfig(
      auth0Config
    );
    return authClientConfig;
  }

  getDeviceInfo() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    delete this.deviceInfo["userAgent"];
    let deviceInformation = `${this.deviceInfo.deviceType}(${this.deviceInfo.os})`;
    console.log("Device Information",deviceInformation);
    
    return deviceInformation;
  }

  async lastLoginInfoUpdate(){
    const data = await this.idTokenClaims();
    const deviceInformation = this.getDeviceInfo();
    this.userService
      .updateLastLogin(data?.email, deviceInformation)
      .subscribe((result) => {
        if (result) {
        }
      });
  }
}