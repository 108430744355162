import { Injectable, signal } from "@angular/core";
import { appDisplayName } from "src/assets/config/constants";

@Injectable({
  providedIn: "root",
})
export class AppBarTitleService {
  applicationTitle: string = appDisplayName;
  pageTitleSignal = signal(appDisplayName);
  
  constructor() {
    this.pageTitleSignal.set(this.applicationTitle);
  }


  setPageTitle(newTitle: string): void {
    this.pageTitleSignal.set(newTitle);
  }
}
