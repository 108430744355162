<mat-accordion *ngFor="let cardDetails of commanCardDetails | slice:0:listOfCount; trackBy:trackByCardDetails">
    <mat-expansion-panel #card>
        <mat-expansion-panel-header>

            <div class="title-container">
                <div class="quote-name-and-id" #headerTooltip="matTooltip" [matTooltip]="cardDetails.cardHeaderTitle"
                    (click)="headerTooltip.toggle(); $event.stopPropagation()">
                    {{ cardDetails.cardHeaderTitle.length > 12 ?
                    (cardDetails.cardHeaderTitle | slice:0:12) + '...' :
                    cardDetails.cardHeaderTitle }} -
                    {{cardDetails.cardHeaderNumber}}
                </div>
              
                <div class="status-and-expansion-btn">
                    <div class="status" *ngIf="typeOfCard === CardType.Quotes && !isArchivedQuotes">
                        Active
                    </div>
                    <div class="status-archived" *ngIf="typeOfCard === CardType.Quotes && isArchivedQuotes">
                        Archived
                    </div>
                    <div *ngIf="typeOfCard === CardType.Orders" [ngClass]="{'status':cardDetails.cardStatusOrType ==='Activated','staus-pending':cardDetails.cardStatusOrType ==='Pending Delivery'}">
                        {{cardDetails.cardStatusOrType }}
                      </div>
                      <div *ngIf="typeOfCard === CardType.Projects" [ngClass]="{'project-status':isArchivedQuotes,'status-archived':!isArchivedQuotes}" >
                        {{ !isArchivedQuotes ? 'Inactive' : cardDetails.cardStatusOrType | slice:0:15 }}
                      </div>
                    <div *ngIf="!card.expanded" class="expansion-icon">
                        <img src="../../../../assets/img/icons/expansion-down.svg" alt="expansion icon for card list" />
                    </div>
                    <div *ngIf="card.expanded" class="expansion-icon">
                        <img src="../../../../assets/img/icons/expansion-up.svg" alt="expansion icon for card list" />
                    </div>
                </div>
            </div>

            <ng-container  *ngIf="typeOfCard === CardType.Quotes">
                <div class="container status-container" fxLayoutAlign="start center">
                      <div *ngIf="enhanceQuoteUiFlag" class="progress-step" [ngClass]="{
                        filled: [0,1, 2, 3, 4, 5].includes(cardDetails.quoteCurrentStep - 1),'progress-step-archive':isArchivedQuotes
                      }"></div>
                    <div class="progress-step" [ngClass]="{
                              filled: [1, 2, 3, 4, 5].includes(cardDetails.quoteCurrentStep - 1),'progress-step-archive':isArchivedQuotes
                            }"></div>
                    <div class="progress-step" [ngClass]="{
                              filled: [2, 3, 4, 5].includes(cardDetails.quoteCurrentStep - 1),'progress-step-archive':isArchivedQuotes 
                            }"></div>
                    <div class="progress-step" [ngClass]="{
                              filled: [3, 4, 5].includes(cardDetails.quoteCurrentStep - 1),'progress-step-archive':isArchivedQuotes
                            }"></div>
                    <div class="progress-step" [ngClass]="{
                              filled: [4, 5].includes(cardDetails.quoteCurrentStep - 1),'progress-step-archive':isArchivedQuotes 
                            }"></div>
                    <div class="progress-step" [ngClass]="{
                              filled: [5].includes(cardDetails.quoteCurrentStep - 1),'progress-step-archive':isArchivedQuotes
                            }"></div>
                  </div>
                  <div *ngIf="cardDetails && cardDetails.archievedConfirm !== undefined && cardDetails.archievedConfirm === 0 " class="mb-3" >
                  </div>
                  <div *ngIf="isArchivedQuotes" class="mb-3"></div>
                  <div *ngIf="cardDetails && cardDetails.archievedConfirm !== undefined && cardDetails.archievedConfirm > 0 && cardDetails.archievedConfirm <= 5" class="archieved_alert">
                    <span>{{cardDetails.archievedConfirm}} day{{cardDetails.archievedConfirm > 1 ? "s" : ''}} left to archive.</span>
                  </div>
            </ng-container>
           
        </mat-expansion-panel-header>
        <div  *ngIf="typeOfCard === CardType.Quotes || typeOfCard === CardType.Orders"  class="quote-info">
            <div class="project-info-and-action-btn">
                <div class="project-info" #projectTooltip="matTooltip" [matTooltip]="cardDetails.projectName"
                    (click)="projectTooltip.toggle(); $event.stopPropagation()">
                    {{ cardDetails.projectName.length > 10 ?
                    (cardDetails.projectName | slice:0:10) + '...' :
                    cardDetails.projectName }} -
                    {{ cardDetails.projectId }}
                </div>

                <ng-container  *appHideIfReadOnly>
                    <div class="action-btns"  *ngIf="typeOfCard === CardType.Quotes"  >
                        <button class="icon-button" matTooltip="Clone Quote">
                            <img  src="../../../../assets/img/icons/clone-icon.svg" (click)="onClone(cardDetails.cardId??'')" alt="clone icon" />
                        </button>
                        <img src="../../../../assets/img/icons/edit-new-icon.svg" alt="edit icon" (click)="toCompleteQuote(cardDetails)" />
                        <img src="../../../../assets/img/icons/trash.svg" alt="delete icon"  (click)="deleteQuote(cardDetails)"   />
                    </div>
                </ng-container>
            </div>
            <div class="quote-dates">
                Start Date: {{ (cardDetails.cardStartDate | date : "MM/dd/yyyy") || "-" }}
            </div>
            <div class="quote-dates-and-navigation">
                <div class="quote-dates">
                    End Date: {{  cardDetails.cardEndDate?.includes('2049') ? '-' :((cardDetails.cardEndDate | date : "MM/dd/yyyy") || "-") }}
                </div>
                <div *ngIf="typeOfCard === CardType.Quotes" class="quote-navigation" (click)="toCompleteQuote(cardDetails)">
                    <ng-container *ngIf="!isInactiveAccount">
                        <span *ngIf="!isArchivedQuotes">Complete Quote</span>
                        <span *ngIf="isArchivedQuotes">View Quote</span>
                    <img src="../../../../assets/img/arrow_right.svg" alt="navigate to Quote creation" />
                </ng-container>
                </div>
                <div *ngIf="typeOfCard === CardType.Orders" class="quote-navigation" (click)="viewOrder(cardDetails)">
                    <span >View Order   </span>
                    <img src="../../../../assets/img/arrow_right.svg" alt="navigate to order details icon" />
                </div>
            </div>
        </div>

        <div *ngIf="typeOfCard === CardType.Projects" class="project-info">
            <div class="project-info-and-action-btn">
                <div class="project-dates">
                    Start Date: {{ (cardDetails.cardStartDate | date : "MM/dd/yyyy") || "-" }}
 
                </div>
                <div class="project-dates">
                    End Date: {{ (cardDetails.cardEndDate | date : "MM/dd/yyyy") || "-" }}
                </div>
            </div>
            
            <div class="quote-dates-and-navigation mt-3">
                 <div class="quote-and-order-count">
                    <div class="quote-count">
                        {{cardDetails.noOfQuotes}}
                    </div>
                    <span>Active Quotes</span>
                    <div class="quote-count">
                        {{cardDetails.noOfOrders}}
                    </div>
                    <span >Active Orders</span>
                 </div>
                <div class="quote-navigation" (click)="viewProject(cardDetails)">
                    <span>View Project </span>
                    <img src="../../../../assets/img/arrow_right.svg" alt="navigate to project details icon" />
                </div>
            </div>
        </div>

    </mat-expansion-panel>
</mat-accordion>

<div *ngIf="commanCardDetails.length === 0" class="no-data">
    No data is available.
</div>


  