import { Component, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigService } from "src/app/shared/services/config/config.service";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { OrderService } from "../../services/order.service";
import { ToastrService } from "ngx-toastr";
import { DatePipe } from "@angular/common";
import { pickupReasonList,PickupReason } from "src/assets/config/pickup-reason-list";
import { WeekendDayValidationPopupComponent } from "src/app/shared/components/dialogbox/weekend-day-validation-popup/weekend-day-validation-popup.component";
@Component({
  selector: "app-calender-popup",
  templateUrl: "./calender-popup.component.html",
  styleUrls: ["./calender-popup.component.scss"],
})
export class CalenderPopupComponent {
  orderData: {} = {};
  minDate: Date;
  maxDate: Date = new Date();
  reasonTypeList:PickupReason[] = pickupReasonList;
  cancelOrderForm: FormGroup;
  constructor(
    public dialog: MatDialog,
    private configService: ConfigService,
    public fb: FormBuilder,
    public router: Router,
    private orderService: OrderService,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public cancelOrderData: any,
    private dialogRef: MatDialogRef<CalenderPopupComponent>
  ) {
    this.cancelOrderForm = this.fb.group({
      endDate: ["", [Validators.required]],
      pickupReasonCode: ["", [Validators.required]],
      note: [""],
    });
    dialogRef.disableClose = true;
  }

  dateCalc = (count: number, date: Date = new Date()) => {
    const ogDate = date;
    const changedDate = ogDate.setDate(ogDate.getDate() + count);

    return new Date(changedDate);
  };

  ngOnInit() {

    this.minDate = this.dateCalc(
      this.configService.getConfigProperty("DATE_GAP")
    );

    this.maxDate = new Date(this.cancelOrderData.endDate);

    if(this.cancelOrderData.pickupReason){
      const pickupReason =pickupReasonList.find((reason) => reason.name===this.cancelOrderData.pickupReason)
      this.cancelOrderForm.patchValue({
        pickupReasonCode: pickupReason?.value,
      });
    }
  }

  transformDate(date: Date) {
    return this.datePipe.transform(date, "yyyy-MM-dd");
  }
  checkDayOfWeek(event) {
    const selecteddate = new Date(event.value);
    const dayOfWeek = selecteddate.getDay();
  
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      this.dialog.open(WeekendDayValidationPopupComponent, {
        panelClass: "limit-exceed-dialog",
        data: {
          isEditedData: "yes",
        },
      });
    
    }
    
  }

  onConfirm() {
    if (this.cancelOrderForm.valid) {
      
      console.log("Selected date",this.cancelOrderForm.value.endDate)
      
      this.orderData = {
        id: this.cancelOrderData.contractId,
        endDate: this.transformDate(this.cancelOrderForm.value.endDate),
        pickupReasonCode: this.cancelOrderForm.value.pickupReasonCode,
        note: this.cancelOrderForm.value.note,
      };
      
      this.orderService.cancelOrder(this.orderData).subscribe((result) => {
        if (result) {
          const pickupReason =pickupReasonList.find((reason) => reason.value===this.cancelOrderForm.value.pickupReasonCode)
          this.dialogRef.close({
            endDate: this.transformDate(this.cancelOrderForm.value.endDate),
            pickupReason: pickupReason,
            note: this.cancelOrderForm.value.note,
          });
          this.toastr.success("Pickup Date Updated Successfully.");
          if(!this.cancelOrderData.stopNavigation){
            this.router.navigate(["orders"]);
          }
        }
      });
    } else {
      this.cancelOrderForm.markAllAsTouched();
    }
  }
}
