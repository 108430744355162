import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ContentHeaderComponent } from "./components/content-header/content-header.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { GoogleMapsModule, MapMarker } from "@angular/google-maps";
import { ZippopupComponent } from "./components/dialogbox/zippopup/zippopup.component";
import { ReactiveFormsModule } from "@angular/forms";
import { NgScrollbarModule } from "ngx-scrollbar";
import { SurpassedDatePopupComponent } from "./components/dialogbox/surpassed-date-popup/surpassed-date-popup.component";
import { OrderListsComponent } from "./components/order-lists/order-lists.component";
import { PromotionComponent } from "./components/promotion/promotion.component";
import { RestrictedAccessComponent } from "./components/restricted-access/restricted-access.component";
import { SiteAddressExistPopupComponent } from "./components/dialogbox/site-address-exist-popup/site-address-exist-popup.component";
import { LocationsComponent } from "./components/locations/locations.component";
import { TermsAndConditionPopupComponent } from "./components/dialogbox/terms-and-conditions-popup/terms-and-conditions-popup.component";
import { CaseListComponent } from "./components/case-list/case-list.component";
import { FormsModule } from "@angular/forms";
import { DatePipe } from "@angular/common";
import { CardListMobileViewComponent } from "./components/card-list-mobile-view/card-list-mobile-view.component";
import { SelectLocationComponent } from "./components/dialogbox/select-location/select-location.component";
import { EditOrderLimitExceedComponent } from "./components/dialogbox/edit-order-limit-exceed/edit-order-limit-exceed.component";
import { TileComponent } from "./components/tile/tile.component";
import { UnitAndServicesComponent } from "./components/units-and-services/unit-and-services.component";
import { QuoteNameComponent } from "./components/quote-name/quote-name.component";
import { SelectLocationToMoveComponent } from "./components/select-location-to-move/select-location-to-move.component";
import { CaseSuccessComponent } from "./components/dialogbox/case-success/case-success.component";
import { SignupPopupComponent } from "./components/dialogbox/signup-popup/signup-popup.component";
import { HideIfReadOnlyDirective } from "./directives/hide-if-read-only/hide-if-read-only.directive";
import { ShowIfReadOnlyDirective } from "./directives/show-if-read-only/show-if-read-only.directive";
import { AddCommentDialogComponent } from "./components/dialogbox/add-comment-dialog/add-comment-dialog.component";
import { ContractslocationComponent } from "./components/contractslocation/contractslocation.component";
import { UserMenuComponent } from "./components/user-menu/user-menu.component";
import { SearchUnitNumberComponent } from "./components/dialogbox/search-unit-number/search-unit-number.component";
import { OrderSummaryHeaderComponent } from "./components/order-summary-header/order-summary-header.component";
import { ToolTipTemplateComponent } from "./components/tool-tip-template/tool-tip-template.component";
import { MtxTooltipModule } from "@ng-matero/extensions/tooltip";
import { AttachmentsDialogComponent } from "./components/dialogbox/attachments-dialog/attachments-dialog.component";
import { UploadDialogComponent } from "./components/dialogbox/upload-dialog/upload-dialog.component";
import { FileDragNDropDirective } from "./directives/file-drag-and-drop/file-drag-n-drop.directive";
import { WeekendDayValidationPopupComponent } from "./components/dialogbox/weekend-day-validation-popup/weekend-day-validation-popup.component";
import { UserHelpComponent } from "./components/user-help/user-help.component";
import { FindUnitsOptionsComponent } from "./components/find-units-options/find-units-options.component";
import { FixedButtonDirective } from "./directives/fixed-button/fixed-button.directive";
import { HideIfMediaQueryDirective } from "./directives/media-query/hide-if-media-query.directive";
import { NotificationsComponent } from "./components/notifications/notifications.component";
import { CardComponent } from "./components/card/card.component";
import { LocationAssignmentComponent } from './components/location-assignment/location-assignment.component';
import { LocationSummaryComponent } from './components/location-summary/location-summary.component';
import { LocationManagerQuoteSummaryComponent } from './components/location-manager-quote-summary/location-manager-quote-summary.component';
import { LinkcomponentComponent } from "./components/linkcomponent/linkcomponent.component";
import { ReasonPopComponent } from './components/dialogbox/reason-pop/reason-pop.component';
import { SelectProjectComponent } from './components/dialogbox/select-project/select-project.component';
import { ProjectLocationComponent } from './components/project-location/project-location.component';
import { EnhanceQuoteToolTipComponent } from './components/enhance-quote-tool-tip/enhance-quote-tool-tip.component';
import { UnsavedChangesComponent } from "./components/dialogbox/unsaved-changes/unsaved-changes.component";
import { PipesModule } from "../theme/pipes/pipes.module";
import { CommanListCardComponent } from './components/comman-list-card/comman-list-card.component';
import { ClonePopupComponent } from "./components/dialogbox/clone-popup/clone-popup.component";


@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgScrollbarModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    GoogleMapsModule,
    FormsModule,
    MtxTooltipModule,
    PipesModule,
    ToolTipTemplateComponent
],
  exports: [
    ReactiveFormsModule,
    FlexLayoutModule,
    NgScrollbarModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    ContentHeaderComponent,
    BreadcrumbComponent,
    GoogleMapsModule,
    OrderListsComponent,
    PromotionComponent,
    LocationsComponent,
    RestrictedAccessComponent,
    CaseListComponent,
    CardListMobileViewComponent,
    EditOrderLimitExceedComponent,
    TileComponent,
    UnitAndServicesComponent,
    QuoteNameComponent,
    ContractslocationComponent,
    HideIfReadOnlyDirective,
    ShowIfReadOnlyDirective,
    UserMenuComponent,
    OrderSummaryHeaderComponent,
    MtxTooltipModule,
    FileDragNDropDirective,
    UserHelpComponent,
    FindUnitsOptionsComponent,
    FixedButtonDirective,
    HideIfMediaQueryDirective,LocationAssignmentComponent,LocationSummaryComponent,LocationManagerQuoteSummaryComponent,
    NotificationsComponent,
    CardComponent,LinkcomponentComponent,
    SelectLocationToMoveComponent,
    ProjectLocationComponent,
    EnhanceQuoteToolTipComponent,
    UnsavedChangesComponent,
    CommanListCardComponent
  ],
  declarations: [
    ContentHeaderComponent,
    BreadcrumbComponent,
    ZippopupComponent,
    SurpassedDatePopupComponent,
    OrderListsComponent,
    PromotionComponent,
    RestrictedAccessComponent,
    SiteAddressExistPopupComponent,
    LocationsComponent,
    TermsAndConditionPopupComponent,
    CaseListComponent,
    CardListMobileViewComponent,
    SelectLocationComponent,
    EditOrderLimitExceedComponent,
    SignupPopupComponent,
    TileComponent,
    AddCommentDialogComponent,
    HideIfReadOnlyDirective,
    ShowIfReadOnlyDirective,
    UnitAndServicesComponent,
    QuoteNameComponent,
    SelectLocationToMoveComponent,
    CaseSuccessComponent,
    ContractslocationComponent,
    UserMenuComponent,
    SearchUnitNumberComponent,
    OrderSummaryHeaderComponent,
    AttachmentsDialogComponent,
    UploadDialogComponent,
    FileDragNDropDirective,
    WeekendDayValidationPopupComponent,
    UserHelpComponent,
    FindUnitsOptionsComponent,
    FixedButtonDirective,
    HideIfMediaQueryDirective, LocationAssignmentComponent, LocationSummaryComponent, LocationManagerQuoteSummaryComponent,
    CardComponent,
    NotificationsComponent,LinkcomponentComponent, ReasonPopComponent, SelectProjectComponent, ProjectLocationComponent, EnhanceQuoteToolTipComponent,UnsavedChangesComponent, CommanListCardComponent, ClonePopupComponent
  ],
  providers: [DatePipe],
})
export class SharedModule {}
