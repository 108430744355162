import { AuthProvider } from './provider';
/**
 * Auth provider configuration for Auth0.
 *
 * @usage
 * ```typescript
 * const result = await AuthConnect.login(new Auth0Provider(), myConfig);
 * ```
 */
export class Auth0Provider extends AuthProvider {
  /** @hidden */
  async authorizeRequest(manifest, options, config) {
    await this.checkOrGenerateKeys(config);
    const url = manifest['authorization_endpoint'];
    const params = {};
    params['client_id'] = options.clientId;
    params['redirect_uri'] = options.redirectUri;
    params['audience'] = options.audience;
    params['scope'] = options.scope;
    params['nonce'] = this.nonce;
    params['state'] = this.nonce;
    if (this.usePKCE(config)) {
      params['code_challenge_method'] = 'S256';
      params['code_challenge'] = this.key.challenge;
      params['response_type'] = 'code';
    } else {
      params['response_type'] = 'id_token token';
      params['response_mode'] = 'fragment';
    }
    return {
      url,
      params
    };
  }
  /** @hidden */
  async tokenRequest(manifest, options, config) {
    await this.checkOrGenerateKeys(config);
    const url = manifest['token_endpoint'];
    if (this.usePKCE(config)) {
      const payload = {};
      payload['grant_type'] = 'authorization_code';
      payload['client_id'] = options.clientId;
      payload['nonce'] = this.nonce;
      payload['state'] = this.nonce;
      payload['code_verifier'] = this.key.verifier;
      payload['redirect_uri'] = options.redirectUri;
      return {
        url,
        payload,
        tokenCodeName: 'code'
      };
    }
    return {
      url
    };
  }
  /** @hidden */
  async refreshTokenRequest(auth) {
    if (!auth) {
      throw new Error('auth result is missing');
    }
    const info = await this.tokenRequest(auth.provider.manifest, auth.provider.options, auth.config);
    if (!info.payload) {
      info.payload = {};
    }
    info.payload['grant_type'] = 'refresh_token';
    info.tokenCodeName = 'refresh_token';
    return info;
  }
  /** @hidden */
  async logoutRequest(auth) {
    if (!auth) {
      throw new Error('auth result is missing');
    }
    await this.checkOrGenerateKeys(auth.config);
    const url = auth.provider.manifest['issuer'] + 'logout';
    const params = {};
    params['client_id'] = auth.provider.options.clientId;
    params['returnTo'] = auth.provider.options.logoutUrl;
    return {
      url,
      params
    };
  }
}
