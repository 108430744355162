import { DeviceInfo } from './../../../../../node_modules/ngx-device-detector/lib/device-detector.service.d';
import { Injectable } from "@angular/core";
import { BehaviorSubject, catchError, map, of, take } from "rxjs";
import { UserModel } from "src/app/models/user-model";
import { UssApiService } from "../../myuss/services/uss-api/uss-api.service";
import { LoadingService } from "src/app/shared/services/loading/loading.service";
import { ApiRespDTO } from "src/app/models/api.model";


@Injectable({
  providedIn: "root",
})
export class UserService {
  public currentUser$ = new BehaviorSubject<UserModel>(new UserModel());
 


  constructor(
    private _api: UssApiService,
    private _loadingService: LoadingService,
  
  ) {}

  updateUserValues(data: Partial<UserModel>) {
    const currentState = this.currentUser$.value;

    this.currentUser$.next({
      ...currentState,
      ...data,
    });
  }

  // get all users api
  fetchUserData(accountId: string | undefined) {
    return this._api
      .post(`users/fetch-user-contacts`, {
        isAllContacts: false,
        accountId: accountId,
      })
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res;
          } else {
            return null;
          }
        }),
        catchError((err) => {
          console.error(`error getting user data: ${JSON.stringify(err)}`);
          this._loadingService.setLoader(false);
          return of(null);
        })
      );
  }

  //add user api
  addUser(userData) {
    return this._api.post(`users/add-user-contact`, userData).pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000) {
          return res;
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(`error getting adding user: ${JSON.stringify(err)}`);
        this._loadingService.setLoader(false);
        return of(null);
      })
    );
  }

  //edit user api
  editUser(userData) {
    return this._api.post(`users/edit-user-contact`, userData).pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000) {
          return res;
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(`error getting editing user: ${JSON.stringify(err)}`);
        this._loadingService.setLoader(false);
        return of(null);
      })
    );
  }


   //update last login api
   updateLastLogin(emailId: string | undefined, deviceInfo: string | undefined) {
    return this._api.post(`auth0/last-login`,  {
      emailId: emailId,
      deviceInfo:deviceInfo
    }).pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000) {
          return res;
        } else {
          return null;
        }
      }),
      catchError((err) => {
        console.error(`error getting editing user: ${JSON.stringify(err)}`);
        this._loadingService.setLoader(false);
        return of(null);
      })
    );
  }

 
}

