import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'com.myuss.mysiteservices',
  appName: 'MyUSS',
  webDir: 'dist/browser',
  server: {
    androidScheme: 'https'
  },
  plugins: {
    FirebaseCrashlytics: {  
      apiKey: "AIzaSyBGrlr5WM3-W9XhRENythtloV6_2kq7_Tc",
      authDomain: "uss-portal-dev.firebaseapp.com",
      projectId: "uss-portal-dev",
      storageBucket: "uss-portal-dev.appspot.com",
      messagingSenderId: "209644420187",
      appId: "1:209644420187:web:a6862137180e51508e537e",
      measurementId: "G-R3XTHHYLZW",
      crashlyticsCollectionEnabled: true
    },
    SplashScreen: {
      launchAutoHide: false
    },
    LiveUpdates: {
      enabled:true,
      appId: '9804f891',
      channel: '',
      autoUpdateMethod: 'none',
      maxVersions: 2
    },
    PushNotifications: {
      presentationOptions: ["badge", "sound", "alert"]
    }
  },
 
}; 

export default config;
