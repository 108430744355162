import { Injectable, signal } from "@angular/core";
import { UssApiService } from "../../myuss/services/uss-api/uss-api.service";
import { catchError, map, of } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { AssetLocation, WorkOrder } from "src/app/models/order-model";

@Injectable({
  providedIn: "root",
})
export class ScanService {
  assetDetailsSignal = signal<{
    assetDetails:  AssetLocation,
    completedServices: WorkOrder[]|[],
    upcomingServices: WorkOrder[]|[],
  }|null>(null);
 


  constructor(private api: UssApiService) {}

  setAssetDetails(assetDetails: {
    assetDetails: AssetLocation;
    completedServices: WorkOrder[] | [];
    upcomingServices: WorkOrder[] | [];
  } |null) {
    this.assetDetailsSignal.set(assetDetails);
  }

  getAssetDetailsByUnitNumber(unitNumber: string, action: string, limit: number) {
    let queryParams = new HttpParams()
      .append("unitNumber", unitNumber)
      .append("action", action)
      .append("limit", limit.toString());
  
    return this.api.get(`contracts/search-unit-number`, { params: queryParams }).pipe(
      map((res) => {
        if (res.status === 1000) {
          return res.data;
        }
      }),
      catchError((err) => {
        console.error(
          `error getting asset by unit number: ${JSON.stringify(err)}`
        );
        throw err;
      })
    );
  }
  
  

 
}
