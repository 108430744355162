import { Component, Inject, effect } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { CaseDetailsStateService } from "src/app/features/cases/services/case-details-state.service";
import { commentOption } from "src/assets/config/constants";
import { UploadDialogComponent } from "../upload-dialog/upload-dialog.component";
import { ToastrService } from "ngx-toastr";
import { Platform } from "@ionic/angular";


@Component({
  selector: "app-add-comment-dialog",
  templateUrl: "./add-comment-dialog.component.html",
  styleUrls: ["./add-comment-dialog.component.scss"],
})
export class AddCommentDialogComponent {
  charCount: number = 0;
  commentForm: FormGroup;
  commentOptions = commentOption;
  contactId: string;
  caseId: string;
  accountId: string;
  isMobile:boolean=false;

  constructor(
    public dialogRef: MatDialogRef<AddCommentDialogComponent>,
    private fb: FormBuilder,
    private profileService: ProfileService,
    private caseDetailsService: CaseDetailsStateService,
    private dialog: MatDialog,
    public toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private platform: Platform
  ) {
    effect(() => {
      this.contactId = this.profileService.selectedAccount()?.contactId ?? "";
      this.accountId = this.profileService.selectedAccount()?.accountId ?? "";
      this.caseId = this.data;  
      });
    
    this.commentForm = this.fb.group({
      commentType: ["", Validators.required],
      comment: ["", [Validators.required, Validators.maxLength(4000)]],
    });

    this.isMobile=this.platform.is('hybrid');
  }

  ngOnInit(): void {
    this.commentForm.get("comment")?.valueChanges.subscribe((value) => {
      this.charCount = value?.length || 0;
    });

    // Initialize charCount with the current length of the comment
    this.charCount = this.commentForm.get("comment")?.value.length || 0;
  }

 
  onCancel(): void {
    this.dialogRef.close();
  }

  addComment(): void {
    if (this.commentForm.valid) {
      const commentData = {
        comment: this.commentForm.get("comment")?.value,
        caseId: this.caseId,
        contactId: this.contactId,
        type: this.commentForm.get("commentType")?.value,
      };
      
      this.caseDetailsService.addComment(commentData, this.accountId, this.caseId).subscribe(
        (res) => {
          console.log("Comment added successfully:", res);
          if (res) {
            // Optionally, reset the form
            this.commentForm.reset();
            const getCaseDetails ={
              accountId: this.accountId,
              caseNumber: this.caseId
            };
            // Close the dialog
            this.dialogRef.close(getCaseDetails);
            //open attachment dialog

              const box = this.dialog 
              .open(UploadDialogComponent, {
                disableClose: true,
                data: {
                  id: res.data?.commentId,
                  type: "comment",
                },
                panelClass: "upload-dialog",
              })
              box?.afterClosed().subscribe((result) => {
                this.caseDetailsService
              .getCaseDetails(this.accountId, this.caseId)
              .subscribe((data) => {});
            });
            
          }
        },
        (error) => {
          console.error("Error adding comment:", error);
        }
      );
    } else {
      this.commentForm.markAllAsTouched();
      console.log("Form is invalid");
    }
  }
  

}
