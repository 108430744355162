
<mat-divider></mat-divider>
<div class="dialog">
    <div class="dialog-content" >
      <mat-icon class="close-icon" (click)="onCancel()" *ngIf="isCommentScreen">close</mat-icon>
      <mat-expansion-panel  #expansionPanel (opened)="panelOpenState.set(true)" (closed)="panelOpenState.set(false)">
        
        
       
        <mat-expansion-panel-header *ngIf="isCommentScreen">
          <h4>Add Attachment for Case Comment</h4>

        </mat-expansion-panel-header>
        
       
         
          
       
          <mat-expansion-panel-header *ngIf="!isCommentScreen">
        <h4 *ngIf="!isCommentScreen">Add Attachments</h4>
        </mat-expansion-panel-header>
        <mat-panel-description>
        <p class="upload-instruction">
          Add your documents here, and you can upload up to 5 files max
        </p>
        <p class="upload-instruction primary" >
          Note: Once you select file to upload, it will be uploaded automatically
        </p>
        <div class="upload-box" fileDragDrop (filesChangeEmiter)="validateFiles($event)">
          <mat-icon class="cloud-icon">cloud_upload</mat-icon>
          <p class="drag">Drag your file(s) here or 
            <label *ngIf="!isAndroid" for="file"><a>browse</a></label>
            <label *ngIf="isAndroid"><a (click)="showDiv()">browse</a></label></p>
          <input #file type="file" name="file" id="file" (change)="validateFiles(file.files)" accept="image/*" multiple> 

          <!-- file-upload.component.html -->
        <div *ngIf="showUploadDiv && isAndroid" fxLayout="column" id="iosFileDiv" class="file-upload-container">
          <div fxLayout="row" fxLayoutAlign="end">
            <span style="background: #f0f0f0; border-radius: 6px 6px 0 0;" class="pt-1 px-1">
              <mat-icon (click)="showUploadDiv=false">cancel</mat-icon>
              <mat-divider></mat-divider>
            </span>
          </div>
          <input class="hide" (change)="validateFiles(fileImg.files)" multiple #fileImg type="file" accept="image/*" >
          <input class="hide" (change)="validateFiles(fileCam.files)" multiple #fileCam type="file" accept="image/*" capture="user">
          <input class="hide" (change)="validateFiles(fileAll.files)" multiple #fileAll type="file">
          <button style="border-radius: 6px 0 0 0;" fxLayoutAlign="space-between" (click)="fileImg.click()">Photo Library <mat-icon matSuffix fxFlexAlign="end">photo_library</mat-icon> </button>
          <mat-divider></mat-divider>
          <button fxLayoutAlign="space-between" (click)="fileCam.click()">Take Photo<mat-icon>camera_alt</mat-icon></button>
          <mat-divider></mat-divider>
          <button style="border-radius: 0 0 6px 6px;" fxLayoutAlign="space-between" (click)="fileAll.click()">Choose Files<mat-icon>folder</mat-icon></button>
       </div>
          <p class="upload-instruction">Each file upload is limited to a maximum size of 5MB</p>


        </div>
        <div>   
            <p class="upload-instruction">Only support .pdf  .doc  .docx  .png  .jpeg  .jpg  .xls  .xlsx files</p>
        </div>
         
    <div class="file-list">
      <div *ngFor="let file of allFiles" class="list-item spinner-wrapper" matTooltip="{{file.name}}">
        <img
        class="document-icon"
        [src]="getImageByExtension(file.name.split('.').pop()||' ')"
        alt="Attchment"
      />
        <span>{{ truncateFileName(file.name, 10)}} </span>
        <div *ngIf="file.status !='Retry'" class="px-1 primary">{{file.status}}</div>
        <div *ngIf="file.status == 'Retry'" class="retry"> <a (click)="onRetry(file)">Retry</a></div>
     
        <div>
          <mat-spinner *ngIf="file.loader" class="primary" [diameter]="20"></mat-spinner>
        </div>
         
      </div>
    </div>
     </mat-panel-description>
</mat-expansion-panel>
</div>
</div>
  
