<div class="custom-card">
  <div
    class="bg-primary"
    style="margin-bottom: 1rem"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
  <h3 style="padding: 0.5rem 0.8rem" *ngIf="!this.cancelOrderData.isShowCalendar">Cancel Order</h3>
  <h3 style="padding: 0.5rem 0.8rem" *ngIf="this.cancelOrderData.isShowCalendar && this.cancelOrderData.isShowCloseBtn">Close Order</h3>
  <h3 style="padding: 0.5rem 0.8rem" *ngIf="this.cancelOrderData.isShowCalendar && !this.cancelOrderData.isShowCloseBtn">Change End Date</h3>
</div>

  <div class="p-2 pr-3 pl-3">
    <p class="pb-2 info" *ngIf="this.cancelOrderData.showCancelationNote"> Note: All units on the site are requested for pickup, which in effect have lead to cancellation of the order.</p>
    <div [formGroup]="cancelOrderForm" fxLayout="column">
      <div fxLayoutAlign="space-between center">
        <label class="labels">Pickup Date:</label>
        <mat-form-field
          color="primary"
          appearance="outline"
          fxFlex.xs="100"
          class="px-2"
        >
          <input
            matInput
            [min]="minDate"
            [matDatepicker]="datePicker"
            placeholder="MM/DD/YYYY"
            formControlName="endDate"
            (dateChange)="checkDayOfWeek($event)"
            required
          />

          <mat-datepicker-toggle matIconSuffix [for]="datePicker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #datePicker touchUi="true"></mat-datepicker>
          <mat-error
            *ngIf="cancelOrderForm.controls.endDate.errors?.required"
          >
            Date is required.
          </mat-error>
        </mat-form-field>
      </div>
      <div fxLayoutAlign="space-between center">
        <label class="labels">Pickup Reason:</label>

        <mat-form-field
          fxFlex="70"
          class="pt-2 pr-2"
          appearance="outline"
          color="primary"
        >
          <mat-select formControlName="pickupReasonCode">
            <mat-option
              *ngFor="let resasonType of reasonTypeList"
              [value]="resasonType.value"
              >{{ resasonType.name }}
            </mat-option>
          </mat-select>
          <mat-error>Please select the Reason.</mat-error>
        </mat-form-field>
      </div>

      <label class="addNoteLabel">Add Note:</label>

      <div>
        <mat-form-field
          fxFlex="100"
          class="fieldheight px-1 pt-2"
          appearance="outline"
        >
          <input matInput formControlName="note" maxlength="255" #box />

          <mat-hint align="end">{{ box.value.length }}/255</mat-hint>
        </mat-form-field>
      </div>
    </div>

   
    <div class="btnDiv pt-2">
      <button class="btnsize" mat-button mat-dialog-close>No</button>
      <button
        class="btnsize"
        mat-raised-button
        color="primary"
        [disabled]="!cancelOrderForm.valid"
        (click)="onConfirm()"
      >
        Confirm
      </button>
     
    
  </div>
</div>
