/* eslint-disable @typescript-eslint/no-unused-vars */
import { registerPlugin, Capacitor } from '@capacitor/core';
import { AuthProvider } from './providers/provider';
function getImplementation() {
  let AuthConnectImpl;
  if (window.cordova !== undefined && window.cordova.platformVersion !== undefined && window.cordova.platformVersion !== '1.0.0') {
    console.log('[AUTH CONNECT] Using Cordova Compatibility Layer.');
    AuthConnectImpl = new Proxy({}, {
      get(_target, property) {
        return window.AuthConnect[property];
      }
    });
  } else {
    console.log('[AUTH CONNECT] Using Capacitor Layer.');
    AuthConnectImpl = registerPlugin('AuthConnect', {
      web: () => import('./web').then(m => new m.AuthConnectWeb())
    });
  }
  return AuthConnectImpl;
}
export class AuthConnect {
  /** @hidden */
  constructor() {
    // ignore
  }
  /**
   * Initialize AuthConnect with platform level configuration.
   *
   * @param config
   * @usage
   * ```typescript
   * await AuthConnect.setup(myConfig);
   * ```
   * @returns
   */
  static async setup(config) {
    if (AuthConnect.impl === undefined) {
      AuthConnect.impl = getImplementation();
    }
    if (Capacitor.getPlatform() !== 'web') {
      delete config.onLoginCallback;
      delete config.onLogoutCallback;
    }
    return await AuthConnect.impl.setup({
      config
    });
  }
  /**
   * Download the auth provider manifest from the specified discoveryUrl.
   *
   * @param discoveryUrl
   * @returns Manifest
   */
  static fetchManifest(discoveryUrl) {
    return AuthConnect.impl.fetchManifest({
      discoveryUrl
    });
  }
  /**
   * Get the current configuration object used by AuthConnect
   *
   * @usage
   * ```typescript
   * const config = await AuthConnect.getConfig();
   * ```
   *
   * @returns AuthConnectConfig
   */
  static getConfig() {
    return AuthConnect.impl.getConfig();
  }
  /**
   * Display the auth provider's login UI, and begin the login process.
   *
   * @param provider
   * @param options
   * @usage
   * ```typescript
   * const result = await AuthConnect.login(getProvider(), myProviderConfig);
   * ```
   * @returns AuthResult
   */
  static async login(provider, options) {
    const manifest = await this.fetchManifest(options.discoveryUrl);
    const config = await this.getConfig();
    const authProvider = {
      options,
      config: await provider.authorizeRequest(manifest, options, config),
      manifest,
      authorizeUrl: ''
    };
    if (config.logLevel === 'DEBUG') {
      console.debug('Auth Provider Config', authProvider.config);
    }
    const tokenProvider = {
      options,
      config: await provider.tokenRequest(manifest, options, config),
      manifest,
      authorizeUrl: ''
    };
    if (config.logLevel === 'DEBUG') {
      console.debug('Token Provider Config', tokenProvider.config);
    }
    return AuthConnect.impl.login({
      provider: authProvider,
      tokenProvider
    });
  }
  /**
   * Log the user out of the auth provider.
   *
   * @param provider
   * @param auth
   * @usage
   * ```typescript
   * await AuthConnect.logout(getProvider(), authResult);
   * ```
   */
  static async logout(provider, auth) {
    auth.provider = Object.assign(Object.assign({}, auth.provider), {
      config: await provider.logoutRequest(auth)
    });
    return AuthConnect.impl.logout({
      auth
    });
  }
  /**
   * Returns decoded token data from the specified TokenType.
   *
   * @param tokenType
   * @param auth
   * @param parseHeader
   * @usage
   * ```typescript
   * const data = await AuthConnect.decodeToken<MyType>(TokenType.id, authResult);
   * ```
   * @returns
   */
  static decodeToken(tokenType, auth, parseHeader) {
    return AuthConnect.impl.decodeToken({
      auth,
      tokenType,
      header: parseHeader !== null && parseHeader !== void 0 ? parseHeader : false
    });
  }
  /**
   * Get the raw token string from the specified TokenType.
   *
   * @param tokenType
   * @param auth
   * @usage
   * ```typescript
   * const tokenString = await AuthConnect.getToken(TokenType.access, authResult);
   * ```
   * @returns
   */
  static async getToken(tokenType, auth) {
    const token = await AuthConnect.impl.getToken({
      auth,
      tokenType
    });
    return token.token;
  }
  /**
   * Get the time until the access token expires, in milliseconds.
   *
   * @param auth
   * @usage
   * ```typescript
   * const expiresIn = await AuthConnect.getAccessTokenExpiration(authResult);
   * ```
   * @returns number
   */
  static async getAccessTokenExpiration(auth) {
    const result = await AuthConnect.impl.getAccessTokenExpiration({
      auth
    });
    return result.expiration;
  }
  /**
   * Checks if access token is available in the provided AuthResult.
   *
   * @param auth
   * @param tokenName
   * @usage
   * ```typescript
   * const isAvailable = await AuthConnect.isAccessTokenAvailable(authResult);
   * ```
   * @returns
   */
  static async isAccessTokenAvailable(auth, tokenName) {
    const result = await AuthConnect.impl.isAccessTokenAvailable({
      auth,
      tokenName
    });
    return result.available;
  }
  /**
   * Checks if the access token is expired.
   *
   * @param auth
   * @usage
   * ```typescript
   * const isExpired = await AuthConnect.isAccessTokenExpired(authResult);
   * ```
   * @returns
   */
  static async isAccessTokenExpired(auth) {
    const result = await AuthConnect.impl.isAccessTokenExpired({
      auth
    });
    return result.expired;
  }
  /**
   * Checks if refresh token is available in the provided AuthResult.
   *
   * @param auth
   * @usage
   * ```typescript
   * const isAvailable = await AuthConnect.isRefreshTokenAvailable(authResult);
   * ```
   * @returns
   */
  static async isRefreshTokenAvailable(auth) {
    const result = await AuthConnect.impl.isRefreshTokenAvailable({
      auth
    });
    return result.available;
  }
  /**
   * Builds an AuthResult object.
   *
   * @param provider
   * @param options
   * @param tokens
   * @usage
   * ```typescript
   * const newAuthResult = await AuthConnect.buildAuthResult(getProvider(), getProviderOptions(), {refreshToken});
   * ```
   * @returns
   */
  static async buildAuthResult(provider, options, tokens) {
    const manifest = await this.fetchManifest(options.discoveryUrl);
    const config = await this.getConfig();
    const authProvider = {
      options,
      config: await provider.authorizeRequest(manifest, options, config),
      manifest,
      authorizeUrl: ''
    };
    if (config.logLevel === 'DEBUG') {
      console.debug('Auth Provider Config', authProvider.config);
    }
    const tokenProvider = {
      options,
      config: await provider.tokenRequest(manifest, options, config),
      manifest,
      authorizeUrl: ''
    };
    if (config.logLevel === 'DEBUG') {
      console.debug('Token Provider Config', tokenProvider.config);
    }
    const opts = {
      provider: authProvider,
      tokenProvider
    };
    const authResult = {
      provider: opts.provider,
      config,
      receivedAt: Math.floor(Date.now() / 1000),
      tokenType: '',
      refreshToken: tokens.refreshToken,
      accessToken: tokens.accessToken,
      idToken: tokens.idToken,
      state: {},
      rawResult: ''
    };
    if (config.logLevel === 'DEBUG') {
      console.debug('Auth Provider Config', authProvider.config);
    }
    return authResult;
  }
  /**
   * Refresh the AuthResult session, throws if refresh token is invalid or missing.
   *
   * @param provider
   * @param auth
   * @usage
   * ```typescript
   * const newAuthResult = await AuthConnect.refreshSession(getProvider(), authResult);
   * ```
   * @returns
   */
  static async refreshSession(provider, auth, scope) {
    const config = await this.getConfig();
    if (scope) {
      auth.provider.options.scope = scope;
    }
    if (provider.refreshTokenRequest) {
      auth.provider = Object.assign(Object.assign({}, auth.provider), {
        config: await provider.refreshTokenRequest(auth)
      });
    } else {
      auth.provider = Object.assign(Object.assign({}, auth.provider), {
        config: await provider.tokenRequest(auth.provider.manifest, auth.provider.options, config)
      });
    }
    return AuthConnect.impl.refreshSession({
      auth
    });
  }
  /**
   * Expire the current access token, but keep the refresh token, useful for testing.
   *
   * @usage
   * ```typescript
   * const expiredAuthResult = await AuthConnect.expire(authResult);
   * ```
   */
  static expire(auth) {
    return AuthConnect.impl.expire({
      auth
    });
  }
  /**
   * Manually called by the hosting app to handle the login callback on web.
   * This is only needed if the hosting app is using `current` for the {@link WebOptions#uiMode}.
   * This method will parse the callback query parameters and return an AuthResult.
   *
   * @param {Object.<string, string>} queryEntries - The key/value pairs (as strings) for the query parameters in the callback url.
   * @param providerOptions - The provider options used during the initial login.
   * @usage
   * ```typescript
   * const urlParams = new URLSearchParams(window.location.search);
   * const queryEntries = Object.fromEntries(urlParams.entries());
   * this.result = await AuthConnect.handleLoginCallback(queryEntries, providerOptions);
   * ```
   * @returns
   */
  static async handleLoginCallback(queryEntries, providerOptions) {
    return AuthConnect.impl.handleLoginCallback({
      queryEntries,
      providerOptions
    });
  }
  /** @hidden */
  handleLogoutCallback() {
    throw new Error('Method not implemented.');
  }
  /** @hidden */
  static async getRandomString(length) {
    const res = await AuthConnect.impl.getRandomString({
      length
    });
    return res.random;
  }
  /** @hidden */
  static getPKCEKey() {
    return AuthConnect.impl.getPKCEKey();
  }
}
export * from './definitions';
export * from './providers/index';
export { AuthProvider };
