import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-clone-popup',
  templateUrl: './clone-popup.component.html',
  styleUrls: ['./clone-popup.component.scss']
})
export class ClonePopupComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    public dialogRef: MatDialogRef<ClonePopupComponent>,


  ){}


  onYes(){
    this.dialogRef.close('Yes')
  }

}
