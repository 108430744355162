export const pickupReasonList = [
  {name: "Job complete",value: "Job complete"},
  {name: "Job ramping down",value: "Job ramping down"},
  {name: "Service quality",value: "Removed from site: Service quality"},
  {name: "Pricing issue",value: "Removed from site: Pricing issue"},
  {name: "Billing issue",value: "Removed from site: Billing issue"},
  ];
export interface PickupReason {
  name: string;
  value: string;
}

   
  
  