import { EnhanceQuoteService } from 'src/app/features/enhance-quotes/services/enhance-quote.service';
import { Component, HostListener, effect } from "@angular/core";
import { UssApiService } from "src/app/features/myuss/services/uss-api/uss-api.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { GroupedNotifications, Notification } from "src/app/models/notification";
import { HomeService } from "src/app/features/home/services/home.service";
import { LoadingService } from "src/app/shared/services/loading/loading.service";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { FormBuilder } from "@angular/forms";
import { notificationDuration,serviceTypes } from "src/assets/config/constants";
import { AppBarTitleService } from 'src/app/features/myuss/services/app-bar-title/app-bar-title.service';

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.scss"],
})
export class NotificationsComponent {
  hide: boolean = false;
  userId: string;
  data;
  alternateMessage:string;
  isExpanded: boolean;
  isMobile: boolean;
  loader: boolean = true;
  account :string|undefined;
  duration=notificationDuration;
  serviceType:string[]=serviceTypes;
  allNotifications:GroupedNotifications[]=[];

  constructor(
    public router: Router,
    private homeService: HomeService,
    public toastr: ToastrService,
    private loadingService: LoadingService,
    private profileService: ProfileService,
    private fb:FormBuilder,
    private enhanceQuoteService:EnhanceQuoteService,
    private appBarTitleService: AppBarTitleService
  ) {
    effect(() => {
      const account = this.profileService.selectedAccount();
      if (!account.accountId) {
        this.hide = true;
        this.alternateMessage = "Upcoming Activities not available at this time.";
      }
      if (account.accountId != this.userId) {
        this.userId = `${account.accountId}`;
        this.getNotification();
      }
    });
  }

  notifictionForm= this.fb.group({
    duration:[7],
    serviceType:["All Types"],
    orderId:[]
  })

  ngOnInit(): void {
    if(this.enhanceQuoteService.enhanceQuoteUiSignal() && this.router.url === "/home/notification"){
      this.appBarTitleService.setPageTitle("Service Activity")
    }
    if (window.innerWidth <= 767) {
      this.isMobile = true;
      console.log("mobile");
    } else {
      this.isMobile = false;
    }
    
  }

  expand() {
    this.isExpanded = true;
  }

  collapse() {
    this.isExpanded = false;
  }

  @HostListener("window:resize") onWindowResize(): void {
    if (window.innerWidth <= 767) {
      this.isMobile = true;
      console.log("mobile");
    } else {
      this.isMobile = false;
    }
  }

  groupByDate (array:Notification[]) {
    this.loader=true;
    const groupedObject = array.reduce((result, currentValue) => {
      if(!currentValue.quoteNo || currentValue.quoteNo.trim()==""){
        return result;
      }
      const date = new Date(currentValue.date).toDateString();
      
      if (!result[date]) {
        result[date] = [];
      }
      result[date].push(currentValue);
      return result;
    }, {});
  
    let data:GroupedNotifications[]= Object.keys(groupedObject).map(key => {
      const dateObject = new Date(key);
      const day = dateObject.toLocaleDateString('en-US', { weekday: 'long' });
        return {
        date: key,
        day,
        events: groupedObject[key]
      };
    }).sort((a,b)=>new Date(a.date).getTime() - new Date(b.date).getTime());
    this.loader = false
    return data;
  };
  
  async getNotification() {
    this.loader=true;
    let daysRange=(this.notifictionForm.controls.duration.value)?this.notifictionForm.controls.duration.value:this.duration[0].days;
     this.homeService.getNotification(this.userId,daysRange).subscribe((res) => {
      this.loader = false;
      if (res) {
        this.hide = false;
         this.allNotifications=this.groupByDate(res);
         this.filterNotificationByServiceType();
      } else {
        this.hide = true;
        this.alternateMessage = "Upcoming Activities not available at this time.";
      }
      this.loadingService.setLoader(false);
    });
  }

  filterNotificationByServiceType(){
    if(this.notifictionForm.controls.serviceType.value!="All Types"){
     this.data= this.allNotifications
     .map(group => ({
       date: group.date,
       day:group.day,
       events: group.events.filter(event => event.status === this.notifictionForm.controls.serviceType?.value)
     }))
     .filter(group => group.events.length > 0);

     
    }else{
      this.data=this.allNotifications;
    }
    
    if(this.data.length){
     this.hide=false
   }else{
     this.alternateMessage= "No data matching the filter"
     this.hide=true
   }
  }

  filterBySearch(searchTerm) {    
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    this.data= this.allNotifications
      .map(group => ({
        date: group.date,
        day:group.day,
        events: group.events.filter(event => 
          event.quoteNo.toLowerCase().includes(lowerCaseSearchTerm)
        )
      }))
      .filter(group => group.events.length > 0); 
      
      if(this.data?.length){
        this.hide=false
      }else{
        this.alternateMessage= "No data matching the filter"
        this.hide=true
      }
  }

  openServiceDetails(orderId:string){
    this.homeService.updateNotificationClickSignal(true);
    this.router.navigate([`/orders/ordersummary/${orderId}`])
  }

}