<div class="p-2 summary_title_wrapper">
  <h3 [ngClass]="{'title_text':screenName=='editOrder'}">Summary</h3>
  <!-- <p>Lorem ipsum dolor sit amet</p> -->
</div>
<section class="p-2 mt-3 summary-section ">

  <div class="size_scroll">
    <div
      *ngFor="let site of siteDetails; let i = index"
      class="site_wise_unit mt-2"
    >
      <div class="new_location p-2">
        <div class="site_details">
          <div class="map_icon_wrapper" fxLayoutAlign="center center  ">
            <img
              src="../../../../assets/img/icons/map.svg"
              alt="Location pin"
            />
          </div>

          <div class="px-2">
            <div class="site_name">
              {{ site?.siteName }}
            </div>

            <div>Placement Note: {{ site?.placementNotes }}</div>
          </div>
        </div>

        <div>
          <mat-icon
            class="expand"
            (click)="expand(i, false)"
            *ngIf="site.expandFlag"
            ><img src="../../../../assets/img/Arrow_Up.svg" alt=""></mat-icon
          >
          <mat-icon
            class="expand"
            (click)="expand(i, true)"
            *ngIf="!site.expandFlag"
            ><img src="../../../../assets/img/Arrow.svg" alt=""></mat-icon
          >
        </div>
      </div>

      <div class="mx-1 quote_details" *ngFor="let bundle of site?.bundles">
        <ng-container *ngIf="site.expandFlag">
          <div class="pt-2 px-2 w-100">
            <p class="site_name">{{ bundle?.assetName }}</p>
            <p>{{ bundle?.serviceName }}</p>
            <div class="additional-services" fxLayoutAlign="space-between center">
              <div fxLayout="row">
              <p class="product-details">Additional Services </p>
              <button
                class="my-1 mx-2 cursor-pointer"
                [disabled]="!bundle.additionalServices?.length"
                id="additional-services-count"
                [matMenuTriggerFor]="serviceMenu"
              >
                {{ bundle.additionalServices?.length }}
              </button>
            </div>
              <div fxLayout="row">
              <p class="product-details">Qty </p>
              <button
                class="my-1 mx-2 cursor-pointer"
                [disabled]="!bundle.additionalServices?.length"
                id="quantity-count"
                [matMenuTriggerFor]="serviceMenu"
              >
              {{ bundle?.quantity }}
              </button>
            </div>
            </div>
            <mat-menu #serviceMenu="matMenu" yPosition="below">
              <p
                mat-menu-item
                *ngFor="let additionalServices of bundle.additionalServices"
              >
                {{ additionalServices.product.description }}
              </p>
            </mat-menu>
          </div>

          <!-- <div class="bundle_quantity blue_text px-2 m-1 mt-2">
            Quantity-{{ bundle?.quantity }}
          </div> -->
        </ng-container>
      </div>
    </div>
  </div>

  <div
    class="my-2 continue-btn w-100"
    [appFixedButton]="'70px'"
    *ngIf="!isMobile && screenName != 'editOrder'"
  >
    <button *ngIf="!enhanceQuoteUiFlag"
      class="w-100"
      fxFlex.xs="0 1 100"
      mat-raised-button
      (click)="onNext()"
      color="primary"
    >
      Next
    </button>
  </div>
  <div  [appFixedButton]="'70px'"
  *ngIf="!isMobile && screenName != 'editOrder'">
    <div class="continue-button" *ngIf="enhanceQuoteUiFlag">
      <button mat-flat-button (click)="onContinue()" class="continue-btn btn">
        <mat-icon matPrefix>arrow_right_alt</mat-icon>Continue
      </button>
    </div>
  </div>

  <div class="button-div"
  *ngIf="isMobile && screenName != 'editOrder'">
    <div class="continue-button" *ngIf="enhanceQuoteUiFlag">
      <button mat-flat-button (click)="onContinue()" class="continue-btn btn">
        <mat-icon matPrefix>arrow_right_alt</mat-icon>Continue
      </button>
    </div>
  </div>
 
</section>
