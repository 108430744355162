<!-- <p>case-list works!</p>
<button mat-raised-button color="primary" routerLink="caseDetails">Primary</button> -->
<!-- for mobile view -->

<section class="wrapper">
  <div class="web-view border-radius-8 white_bg m-3 p-1">
    <mat-card
      *ngIf="
        mobileView &&
        (endPoint === 'cases')
      "
    >
      <mat-card-content class="screen_header">
        <div class="case-back-arrow">
          <mat-icon *ngIf="endPoint === 'cases'" (click)="navigateBack()"
            >keyboard_arrow_left</mat-icon
          >
          <h3 class="case-title">{{ title }}</h3>
        </div>

        <div *ngIf="screen == 'technical-support-list'">
          <button
            class="get_support"
            mat-raised-button
            color="primary"
            (click)="getSupport()"
          >
            Get Support
          </button>
        </div>
      </mat-card-content>
      <p
        class="technical-support-navigation"
        *ngIf="screen !== 'technical-support-list' && endPoint === 'cases'"
      >
        Note - To initiate request for technical support
        <a [routerLink]="['/technical-support']" target="_self"> Click here</a>
        to initiate.
      </p>
      <p
        class="technical-support-navigation"
        *ngIf="screen == 'technical-support-list'"
      >
        Note - For changes to existing orders, please use the
        <a [routerLink]="['/orders']" target="_self"> Order module</a>
      </p>
    </mat-card>

    <!-- case list for desktop view -->
    <div class="mt-3" *ngIf="!mobileView">
      <div>
        <div class="cases-header px-3">
          <div class="child child-one">
            <h3 class="case-title">{{ title }}</h3>
            <p
              class="technical-support-navigation"
              *ngIf="
                screen !== 'technical-support-list' && endPoint === 'cases'
              "
            >
              Note - To initiate request for technical support
              <a [routerLink]="['/technical-support']" target="_self">
                Click here
              </a>
              to initiate.
            </p>
            <p
              class="technical-support-navigation"
              *ngIf="screen == 'technical-support-list'"
            >
              Note - For changes to existing orders, please use the
              <a [routerLink]="['/orders']" target="_self"> Orders module </a>
            </p>
          </div>

          <div class="child child-two">
            <mat-slide-toggle
              [aria-label]="isCaseClosed.toString()"
              color="primary"
              [checked]="isCaseClosed"
              (change)="onToggleChange($event)"
            >
              <span *ngIf="screen != 'technical-support-list'">
                Show closed cases
              </span>
              <span *ngIf="screen == 'technical-support-list'">
                Show closed requests
              </span>
            </mat-slide-toggle>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="subheader_button mt-2 px-3">
          <div class="subheader">
            <div class="filter">
              <mat-form-field
                appearance="outline"
                class="search prefix-icon-position"
              >
                <mat-icon matPrefix>search</mat-icon>
                <input
                  matInput
                  (keyup)="applyFilter($event)"
                  placeholder="Search"
                  #searchInput
                />
              </mat-form-field>
            </div>
            <div *ngIf="isCaseClosed" class="filter">
              <mat-form-field class="selector mt-2" appearance="outline">
                <mat-label>Duration</mat-label>
                <mat-select
                  [(ngModel)]="selectedOption"
                  (selectionChange)="selectedValue()"
                >
                  <mat-option value="30 days">1 Month</mat-option>
                  <mat-option value="60 days">2 Months</mat-option>
                  <mat-option value="90 days">3 Months</mat-option>
                  <mat-option value="Custom Date">Custom Date</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div *ngIf="showFromToDate" class="filter">
              <mat-form-field
                class="example-full-width mt-2"
                appearance="outline"
              >
                <mat-label>From Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="fromDatePicker"
                  (dateChange)="selectedStartDate($event)"
                  [max]="maxDate"
                />
                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                <mat-datepicker-toggle matIconSuffix [for]="fromDatePicker">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div *ngIf="showFromToDate" class="filter">
              <mat-form-field
                class="example-full-width mt-2"
                appearance="outline"
              >
                <mat-label>To Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="toDatePicker"
                  (dateChange)="selectedEndDate($event)"
                  [max]="maxDate"
                  [min]="minEndDate"
                />
                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                <mat-datepicker-toggle matIconSuffix [for]="toDatePicker">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
              </mat-form-field>
            </div>

            <div *ngIf="selectedOption == 'Custom Date'">
              <button
                [disabled]="bothDateSelected()"
                mat-raised-button
                color="primary"
                class="mb-3"
                (click)="getCustomDateClosedCases()"
              >
                Search
              </button>
            </div>
          </div>
          <div *ngIf="screen == 'technical-support-list'">
            <button
              class="get_support"
              mat-raised-button
              color="primary"
              (click)="getSupport()"
            >
              Get Support
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="isCases || isOrderCases" class="px-3">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- Define columns -->
          <ng-container matColumnDef="caseNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <span *ngIf="screen != 'technical-support-list'"> CASE NO. </span>
              <span *ngIf="screen == 'technical-support-list'">
                SUPPORT NO.
              </span>
            </th>
            <td mat-cell *matCellDef="let element">{{ element.caseNumber }}</td>
          </ng-container>
          <ng-container matColumnDef="subject">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="custom-header"
            >
              SUBJECT
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              #nameTollTip="matTooltip"
              [matTooltip]="element.subject"
              (click)="nameTollTip.toggle()"
            >
              {{ element.subject | truncate : 20 }}
            </td>
          </ng-container>
          <ng-container *ngIf="!isOrderScreen" matColumnDef="orderNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ORDER ID</th>
            <td mat-cell *matCellDef="let element">
              {{ getOrderNumber(element.orderNumber) }}
            </td>
          </ng-container>
          <ng-container *ngIf="!isOrderScreen" matColumnDef="siteAddress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              SITE ADDRESS
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container *ngIf="element.orderAddress?.street || element.orderAddress?.city || element.orderAddress?.state || element.orderAddress?.zipcode; else blankTd">
                {{ element.orderAddress?.street }}<br />
                {{ element.orderAddress?.city }},
                {{ element.orderAddress?.state }}
                {{ element.orderAddress?.zipcode }}
              </ng-container>
              <ng-template #blankTd>-</ng-template>
            </td>
          </ng-container>

          <ng-container matColumnDef="createdDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="custom-header"
              mat-sort-header
            >
              OPENING DATE
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdDate | date : "MM/dd/yyyy" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastModifiedDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="custom-header"
              mat-sort-header
            >
              LAST UPDATED
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastModifiedDate | date : "MM/dd/yyyy" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="custom-header"
              mat-sort-header
            >
              REQUEST TYPE
            </th>
            <td mat-cell *matCellDef="let element">
              {{ getCaseType(element.type) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="myusscasetype">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="custom-header"
              mat-sort-header
            >
              MYUSS CASE TYPE
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.myussCaseType }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="custom-header"
              mat-sort-header
            >
              STATUS
            </th>
            <td mat-cell *matCellDef="let element">
              <div
                *ngIf="
                  element.status == 'New' ||
                  element.status == 'Waiting on Submitter' ||
                  element.status == 'In Progress' ||
                  element.status == 'Closed' ||
                  element.status == 'Waiting on USS Team'
                "
              >
                <div *ngIf="element.status == 'New'" class="new-status">
                  {{ element.status }}
                </div>
                <div
                  *ngIf="element.status == 'Waiting on Submitter'"
                  class="waiting-status"
                >
                  {{ element.status }}
                </div>
                <div
                  *ngIf="element.status == 'In Progress'"
                  class="inprogress-status"
                >
                  {{ element.status }}
                </div>
                <div *ngIf="element.status == 'Closed'" class="closed-status">
                  {{ element.status }}
                </div>
                <div
                  *ngIf="element.status == 'Waiting on USS Team'"
                  class="inprogress-status"
                >
                  In Progress
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="custom-header">
              ACTION
            </th>
            <td mat-cell *matCellDef="let element">
              <img
                src="../../../../assets/img/icons/visibility.png"
                class="visibility_css"
                (click)="viewCaseDetails(element.id)"
                alt="View Details"
              />
              <!-- <button class="myButton" (click)="viewCaseDetails(element.id)">
            View Details
          </button> -->
            </td>
          </ng-container>

          <!-- Header row -->
          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"
            class="custom-header"
          ></tr>

          <!-- Data row -->
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
          *ngIf="isCases || isOrderCases"
          [pageSizeOptions]="[10, 20]"
          showFirstLastButtons
          aria-label="Select page of cases"
        ></mat-paginator>
      </div>
      <div
        *ngIf="!isCases && !isOrderScreen"
        class="mt-1"
        fxLayoutAlign="center"
      >
        <h2 class="no-cases" *ngIf="screen != 'technical-support-list'">
          There are no cases related to the account.
        </h2>
        <h2 class="no-cases" *ngIf="screen == 'technical-support-list'">
          There are no support request.
        </h2>
      </div>
      <div
        *ngIf="!isOrderCases && isOrderScreen"
        class="mt-1"
        fxLayoutAlign="center"
      >
        <h2 class="no-cases">There are no cases related to the order.</h2>
      </div>

      <!-- <p> Selected food: {{selectedOption}} selected date: {{fromDate}} {{endDate}}</p> -->
    </div>
    <div class="px-3" *ngIf="mobileView !== false">
      <app-card-list-mobile-view
        [screen]="screen"
        [orderName]="orderName"
        [contractsFilter]="contractsFilter"
      ></app-card-list-mobile-view>
    </div>
  </div>
</section>
