<div class="top_div p-2">
  <h3 *ngIf="screenName != 'editOrder'" class="indicators" >Quote Summary
  </h3>
  <div fxLayout="row" fxLayout="row" fxLayoutAlign="center space-between" *ngIf="screenName!=='editOrder'">
  <div class="indicators">
    <div class="assignment_container mx-1"> <img src="../../../../assets/img/icons/green-indicators.svg" alt=""> </div>
    Assigned
  </div>
  <div class="indicators px-3">
    <div class="assignment_container mx-1"><img src="../../../../assets/img/icons/red-indicators.svg" alt=""></div>
    Unassigned
  </div>
</div>
  <h3 *ngIf="screenName === 'editOrder'" class="title_text">Order Summary</h3>
  <div *ngIf="screenName != 'editOrder'" class="indicators pt-1"></div>
</div>
<section class="white_bg">
  <div class="size_scroll mt-3 p-2" >
    <div
      class="bundle_details mt-2"
      (click)="selectUnit(i)"
      *ngFor="let bundle of bundleData; let i = index"
      [ngClass]="{ selected: bundle?.isSelected }"
    >
      <div class="asset px-2 pt-2 mb-1" [ngClass]="{ card_header: isMobile }">
        <div class="indicators">
          
          <img  *ngIf="getAvailableQuantityByIndex(i) == 0" class="mx-2" src="../../../../assets/img/icons/green-indicators.svg" alt="">
         
          <img  *ngIf="getAvailableQuantityByIndex(i) > 0" class="mx-2" src="../../../../assets/img/icons/red-indicators.svg" alt="">
          <p class="asset_name">
            {{ (bundle?.assetList)![0].product.description }}
          </p>
        </div>

        <div *ngIf="isMobile">
          <mat-icon class="expand" *ngIf="bundle.isSelected"
            ><img src="../../../../assets/img/Arrow_Up.svg" alt=""></mat-icon
          >
          <mat-icon class="expand" *ngIf="!bundle.isSelected"
            ><img src="../../../../assets/img/Arrow.svg" alt=""></mat-icon
          >
        </div>
      </div>
      <div fxLayoutAlign="space-between center">
        <p class="px-3">
          {{
            serviceFrequencyMap[
              (bundle?.services)![0].product.description || ""
            ]
          }}
        </p>
        <div class="additional-services px-3">
          <p class="product-details">Additional Services</p>
          <button
            class="my-1 cursor-pointer"
            [disabled]="!bundle.additionalServices?.length"
            id="additional-services-count"
            [matMenuTriggerFor]="additionalServiceMenu"
          >
            {{ bundle.additionalServices?.length }}
          </button>
        </div>
      </div>
      <mat-menu #additionalServiceMenu="matMenu" yPosition="below">
        <p
          mat-menu-item
          *ngFor="let additionalServices of bundle.additionalServices"
        >
          {{ additionalServices.product.description }}
        </p>
      </mat-menu>

      <div
        class="indicators quantity_left mt-2 p-2"
        [ngClass]="{ selected_bundle: bundle?.isSelected && !isMobile }"
      >
        <p
          class="px-1"
          [ngClass]="{ selected_bundle: bundle?.isSelected && !isMobile }"
        >
          {{ bundle.quantity }} Total quantity
        </p>
        <p [ngClass]="{ selected_bundle: bundle?.isSelected && !isMobile }">
          |
        </p>
        <p
          class="px-1"
          [ngClass]="{ selected_bundle: bundle?.isSelected && !isMobile }"
        >
          {{ getAvailableQuantityByIndex(i) }} Left to assign
        </p>
      </div>

      <div *ngIf="bundle.isSelected">
        <app-location-assignment
          [MobileEditOrderScreen]="EditOrderScreen"
          (editOrderDataSitewise)="handleDrawerDataInLocation($event)"
          (isSingleQuantity)="receiveSingleQuantityMobile($event)"
          *ngIf="this.isMobile"
        ></app-location-assignment>
      </div>
    </div>
  </div>
</section>
