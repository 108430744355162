import { Injectable, signal } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import {
  ActionPerformed,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from "@capacitor/push-notifications";
import { UssApiService } from "../uss-api/uss-api.service";
import { catchError,map } from "rxjs";
import { UserProfile } from "src/app/models/profile-model";
import { FirebaseMessaging } from "@capacitor-firebase/messaging";
import { Toast, ToastrService } from "ngx-toastr";
import { App } from "@capacitor/app";
@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  selectedAccountId: string | undefined;
  notificationBellSignal = signal<boolean>(false);

  constructor(
    private router: Router,
    private api: UssApiService,
    private toaster: ToastrService
  ) {}

  // Method to initialize push notifications
  async initPushNotifications(
    selectedAccount: Partial<UserProfile>
  ): Promise<void> {
    try {
      const permStatus = await PushNotifications.checkPermissions();
      if (permStatus.receive === "prompt") {
        const requestPermission = await PushNotifications.requestPermissions();
        if (requestPermission.receive !== "granted") {
          throw new Error("User denied push notification permissions.");
        }
      } else if (permStatus.receive !== "granted") {
        throw new Error("Push notification permission not granted.");
      }

      await this.registerNotifications();
      await this.addListeners(selectedAccount);
    } catch (error) {
    }
  }

  private async registerNotifications(): Promise<void> {
    await PushNotifications.register();
  }

  private async addListeners(
    selectedAccount: Partial<UserProfile>
  ): Promise<void> {
    const tokenResult = await FirebaseMessaging.getToken();
    this.selectedAccountId = selectedAccount.accountId;

    this.sendFcmTokenToServer({
      fcmToken: tokenResult.token,
      ussPortalUserId: selectedAccount.accountId,
      emailId: selectedAccount.email,
    }).subscribe();

    await PushNotifications.addListener(
      "registration",
      async (token: Token) => {}
    );

    // Listener for receiving a notification
    await PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        this.notificationBellSignal.set(true);    
      }
    );

    // Listener for notification action performed
    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notification: ActionPerformed) => {
        const caseId = notification.notification.data?.caseId;
        if (caseId) {
          const appState = await App.getState();

          if (appState.isActive) {
            this.router.navigateByUrl(`cases/case-details/${caseId}`);
          } else {
            App.addListener("appStateChange", (state) => {
              if (state.isActive) {
                this.router.navigateByUrl(`cases/case-details/${caseId}`);
              }
            });
          }
        }
      }
    );

    await PushNotifications.addListener("registrationError", (err) => {
    });
  }

  getDeliveredNotifications = async () => {
    const notificationList =
      await PushNotifications.getDeliveredNotifications();
  };

  sendFcmTokenToServer(data: {
    fcmToken: string;
    ussPortalUserId: string | undefined;
    emailId: string | undefined;
  }) {
    return this.api
      .post(`accounts/${this.selectedAccountId}/update-fcm-token`, data)
      .pipe(
        map((res) => res["data"]),
        catchError((err) => {
          throw err;
        })
      );
  }

  getPushNotifications(selectedAccount: string) {
    return this.api
      .get(`notifications/${selectedAccount}/get-notifications`)
      .pipe(
        map((res) => res["data"]),
        catchError((err) => {
          throw err;
        })
      );
  }

  displayNotification(notification: PushNotificationSchema) {
    this.toaster.success(notification.body, notification.title);
  }

  resetNotificationFlag() {
    this.notificationBellSignal.set(false);
  }
}
